import { useState } from "react";
import { Box, Button, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteGroup } from "../api/deleteGroup";
import EditSingleGroupModal from "./editSingleGroupModal";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
export const EditSingleGroup = ({ groupData }: any) => {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const navigate = useNavigate();

  const { encryptedClassId, encryptedSubjectId } = useParams();

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleDeleteClick = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onDeleteSubmit = async () => {
    const response = await DeleteGroup(groupData?.id);
    navigate(`/dashboard/${encryptedClassId}/${encryptedSubjectId}`);
    handleDeleteClose();
  };

  return (
    <>
      {/* <div className="flex font-mainfont"> */}
      <Button
        onClick={() => setEditOpen(true)}
        variant="contained"
        color="primary"
      >
        <div className="font-mainfont">تعديل</div>
      </Button>
      {/* </div> */}
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styleClasses}>
          <div className="flex justify-between items-center font-mainfont pb-6">
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={deleteOpen ? "long-menu" : undefined}
                aria-expanded={deleteOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleDeleteClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="font-mainfont">حذف الكروب</div>
          </div>
          <div className="flex justify-end pb-6">
            <div>هل انت متاكد من الكروب</div>
          </div>

          <div className="flex font-mainfont pt-6">
            <Button
              onClick={onDeleteSubmit}
              variant="contained"
              color="primary"
            >
              <div className="font-mainfont">نعم</div>
            </Button>
          </div>
        </Box>
      </Modal>
      <EditSingleGroupModal
        groupData={groupData}
        editOpen={editOpen}
        handleEditClose={handleEditClose}
      />
    </>
  );
};
