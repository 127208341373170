import { useState } from "react";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CloseIcon from "@mui/icons-material/Close";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CreateAdminReq } from "../api/createAdminReq";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../../lib/auth";

const inputSchema = z.object({
  adminFullName: z.string(),
  adminUserName: z.string(),
  adminPassword: z.string(),
});

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const CreateAdmin = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useAuth();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const handleOpen = () => {
    setOpen(true);
    setValue("adminName", ""); // Reset input value when modal is opened
    setValue("adminEmail", "");
    setValue("adminPassword", "");
    clearErrors();
  };
  const queryClient = useQueryClient();

  const handleClose = () => {
    setOpen(false);
  };

  const { mutateAsync } = useMutation(CreateAdminReq, {
    onSuccess: () => {
      queryClient.invalidateQueries(["admins", user.schoolId]);
      handleClose();
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const validatedData = inputSchema.parse(data);

      await mutateAsync({
        schoolId: user.schoolId,
        fullName: validatedData.adminFullName,
        userName: validatedData.adminUserName,
        password: validatedData.adminPassword,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <div>
          <Button onClick={handleOpen} variant="contained" size="large">
            <div className="font-mainfont">انشاء ادمن</div>
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={styleClasses}>
              <div className="flex justify-between items-center font-mainfont pb-6">
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="font-mainfont">اضافة ادمن</div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <TextField
                      fullWidth
                      id="adminFullName"
                      placeholder="ادخل اسم الادمن"
                      {...register("adminFullName", { required: true })}
                      error={!!errors.adminFullName}
                      InputLabelProps={{
                        style: {
                          textAlign: "right", // Align the label to the right
                        },
                      }}
                      inputProps={{
                        style: {
                          textAlign: "right", // Align the input text to the right
                          direction: "rtl", // Set text direction for the input
                        },
                      }}
                      style={{
                        direction: "rtl", // Set text direction for the entire TextField
                        textAlign: "right", // Align the entire TextField to the right
                      }}
                    />
                    {errors.adminFullName && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال اسم الكورس
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont">: اسم الادمن</div>
                </div>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <TextField
                      fullWidth
                      id="adminUserName"
                      placeholder="ادخل اسم المستخدم"
                      {...register("adminUserName", { required: true })}
                      error={!!errors.adminUserName}
                      InputLabelProps={{
                        style: {
                          textAlign: "right", // Align the label to the right
                        },
                      }}
                      inputProps={{
                        style: {
                          textAlign: "right", // Align the input text to the right
                          direction: "rtl", // Set text direction for the input
                        },
                      }}
                      style={{
                        direction: "rtl", // Set text direction for the entire TextField
                        textAlign: "right", // Align the entire TextField to the right
                      }}
                    />{" "}
                    {errors.adminUserName && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال اسم المستخدم الادمن
                      </div>
                    )}
                  </div>{" "}
                  <div className="font-mainfont">: اسم المستخدم الادمن</div>
                </div>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    {/* <TextField
                      fullWidth
                      id="adminPassword"
                      type="password"
                      placeholder="ادخل كلمة المرور"
                      {...register("adminPassword", { required: true })}
                      error={!!errors.adminPassword}
                      helperText={
                        errors.adminPassword ? (
                          <div className="text-right">
                            الرجاء ادخال كلمة المرور
                          </div>
                        ) : (
                          ""
                        )
                      }
                      InputLabelProps={{
                        style: {
                          textAlign: "right", // Align the label to the right
                        },
                      }}
                      inputProps={{
                        style: {
                          textAlign: "right", // Align the input text to the right
                          direction: "rtl", // Set text direction for the input
                        },
                      }}
                      style={{
                        direction: "rtl", // Set text direction for the entire TextField
                        textAlign: "right", // Align the entire TextField to the right
                      }}
                    /> */}
                    <OutlinedInput
                      fullWidth
                      placeholder="ادخل الرمز السري"
                      id="adminPassword"
                      type={showPassword ? "text" : "password"}
                      {...register("adminPassword", { required: true })}
                      error={!!errors.adminPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{
                        style: {
                          textAlign: "right", // Align the input text to the right
                          direction: "rtl", // Set text direction for the input
                        },
                      }}
                      style={{
                        direction: "rtl", // Set text direction for the entire TextField
                        textAlign: "right", // Align the entire TextField to the right
                      }}
                    />{" "}
                    {errors.adminPassword && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال رمز سري
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont">: كلمة المرور</div>
                </div>
                <div className="flex font-mainfont py-6">
                  <Button type="submit" variant="contained" color="primary">
                    <div className="font-mainfont">اضافة</div>
                  </Button>
                </div>
              </form>
            </Box>
          </Modal>
        </div>
        <div>
          <div className="font-mainfont text-3xl">الادمنية</div>
        </div>
      </div>
      <div className="border-b-2 border-mainLine pt-2"></div>
    </>
  );
};

// almost end with the types
