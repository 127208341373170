import React from "react";
import { Route, Routes } from "react-router-dom";
import { AdminWrapper } from "./adminWrapper";
import { AdminProfileWrapper } from "./adminProfileWrapper";

export const AdminRouters = () => {
  return (
    <Routes>
      <Route index element={<AdminWrapper />} />
      <Route path="/:encryptedAdminId" element={<AdminProfileWrapper />} />
    </Routes>
  );
};
