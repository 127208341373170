import { axios } from "../../../lib/axios";

const requestBody = {
  entity: "stage",
};

export const undeleteClassReq = async ({ id }: any) => {
  const response = await axios.patch(`/undelete/${id}`, requestBody);
  return response.data;
};
// done types
