import { axios } from "../../../lib/axios";
// import { ClassTypes } from "../types";

export const EditGroupReq = async ({ GroupId, formData }) => {
  // console.log(data);

  const response = await axios.patch(`/courses/${GroupId}`, formData); // Use stage_name key
  // console.log(response.data);

  return response.data;
};
