import { useEffect } from "react";
import { GroupStudentTable } from "../components/groupStudentTable";
import { GroupTeacherTable } from "../components/groupTeacherTable";
import { SingleGroupData } from "../components/singleGroupData";

export const SingleGroupWrapper = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="pt-5">
        <SingleGroupData />
        <div className="pt-3 p-5">
          <GroupTeacherTable />
          <GroupStudentTable />
          {/* <SubjectList /> */}
        </div>
      </div>
    </>
  );
};
