// import React from "react";
import { Route, Routes } from "react-router-dom";
import { StudentWrapper } from "./studentWrapper";
import { StudentProfileWrapper } from "./studentProfileWrapper";

export const StudentRouters = () => {
  return (
    <Routes>
      <Route index element={<StudentWrapper />} />
      <Route path=":encryptedStudentId" element={<StudentProfileWrapper />} />
    </Routes>
  );
};
