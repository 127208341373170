import { useForm } from "react-hook-form";
import { z } from "zod";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CardMetaData } from "../types";
import { MetaDataCard } from "../../../components/elements/metaDataCard";
import { getClassMetaData } from "../api/getClassMetaData";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { CreateClassReq } from "../api/createClass";
import { Waveform } from "@uiball/loaders";

import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import { useMutation, useQuery, useQueryClient } from "react-query";
const inputSchema = z.object({
  className: z.string(),
});
const Context = React.createContext({ name: "Default" });

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const CreateClass = () => {
  const { checkAccess } = useAuthorization();
  const [open, setOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const { data: classMetaDataStats, isLoading } = useQuery(
    "classData-stats",
    () => getClassMetaData(),
    {
      enabled: !!checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }),
    }
  );

  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(CreateClassReq, {
    onSuccess: () => {
      queryClient.invalidateQueries(["classData"]);
      openNotification("topLeft");
      handleClose();
    },
  });

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم انشاء الصف بنجاح`,
      key: "create-success-notification",
      placement,
    });
  };

  const handleOpen = () => {
    setOpen(true);
    setValue("className", "");
    clearErrors();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    try {
      const validatedData = inputSchema.parse(data);
      const name = validatedData.className;

      await mutateAsync({ name });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={100} speed={2} color="black" />
          </div>
        ) : (
          <div className="flex flex-wrap justify-end py-2">
            {checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) && (
              <>
                <MetaDataCard
                  key={0}
                  index={0}
                  title="عدد الكورسات"
                  data={classMetaDataStats?.array[0].countCourses}
                />{" "}
                <MetaDataCard
                  key={1}
                  index={1}
                  title="عدد الصفوف"
                  data={classMetaDataStats?.array[0].countStages}
                />{" "}
                <MetaDataCard
                  key={2}
                  index={2}
                  title="عدد الطلاب"
                  data={classMetaDataStats?.array[0].countStudents}
                />{" "}
                <MetaDataCard
                  key={3}
                  index={3}
                  title="عدد المواد"
                  data={classMetaDataStats?.array[0].countSubjects}
                />{" "}
                <MetaDataCard
                  key={4}
                  index={4}
                  title="عدد المدرسين"
                  data={classMetaDataStats?.array[0].countTeachers}
                />
              </>
            )}
          </div>
        )}
        <div className="flex justify-between items-center mb-2">
          <div>
            {checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) ? (
              <Button onClick={handleOpen} variant="contained" size="large">
                <div className="font-mainfont">انشاء صف</div>
              </Button>
            ) : (
              ""
            )}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styleClasses}>
                <div className="flex justify-between items-center font-mainfont pb-6">
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div className="font-mainfont">اضافة صف</div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    placeholder="ادخل اسم الصف"
                    fullWidth
                    {...register("className", { required: true })}
                    error={!!errors.className}
                    helperText={errors.className ? "الرجاء ادخال اسم الصف" : ""}
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                  {errors.className && (
                    <div className="text-right text-xs text-red-600 pt-1">
                      الرجاء ادخال اسم الكورس
                    </div>
                  )}
                  <div className="flex font-mainfont py-6">
                    <Button type="submit" variant="contained" color="primary">
                      <div className="font-mainfont">اضافة</div>
                    </Button>
                  </div>
                </form>
              </Box>
            </Modal>
          </div>
          <div>
            <div className="font-mainfont text-3xl">الصفوف</div>
          </div>
        </div>
        <div className="border-b-2 border-mainLine pt-2"></div>
      </Context.Provider>
    </>
  );
};
// done types
