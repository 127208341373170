import { MainLayout } from "../components/Layout/MainLayout";
import { Navigate, Outlet } from "react-router-dom";
import { ClassRouters } from "../features/class";
import { TeacherRouters } from "../features/teachers/routes";
import { AdminRouters } from "../features/admin/router";
import { StudentRouters } from "../features/students/router";
import { ProfileRouters } from "../features/profile/routes";
import { ArchiveRouter } from "../features/Archives/router";

const App = () => {
  return (
    <>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </>
  );
};
export const SuperAdminRoutes = [
  {
    path: "/dashboard/*",
    element: <App />,
    children: [{ path: "*", element: <ClassRouters /> }],
  },
  {
    path: "/teachers/*", // Direct path for teachers
    element: <App />,
    children: [{ path: "*", element: <TeacherRouters /> }],
  },
  {
    path: "/students/*", // Direct path for teachers
    element: <App />,
    children: [{ path: "*", element: <StudentRouters /> }],
  },
  {
    path: "/admins/*", // Direct path for teachers
    element: <App />,
    children: [{ path: "*", element: <AdminRouters /> }],
  },
  {
    path: "/archives/*", // Direct path for teachers
    element: <App />,
    children: [{ path: "*", element: <ArchiveRouter /> }],
  },
  {
    path: "/me/*", // Direct path for teachers
    element: <App />,
    children: [
      {
        // Nested route for profile (e.g., /me/12)
        path: ":encryptedProfileId/*",
        element: <ProfileRouters />,
      },
      {
        // Redirect /me to /dashboard
        path: "*",
        element: <Navigate to="/dashboard" />,
      },
    ],
  },

  {
    path: "*", // Redirect all other routes to the dashboard
    element: <Navigate to="/dashboard" />,
  },
];

export const AdminRoutes = [
  {
    path: "/dashboard/*",
    element: <App />,
    children: [{ path: "*", element: <ClassRouters /> }],
  },
  {
    path: "/teachers/*", // Direct path for teachers
    element: <App />,
    children: [{ path: "*", element: <TeacherRouters /> }],
  },
  {
    path: "/students/*", // Direct path for teachers
    element: <App />,
    children: [{ path: "*", element: <StudentRouters /> }],
  },
  {
    path: "/admins/*", // Direct path for teachers
    element: <App />,
    children: [{ path: "*", element: <AdminRouters /> }],
  },
  {
    path: "/archives/*", // Direct path for teachers
    element: <App />,
    children: [{ path: "*", element: <ArchiveRouter /> }],
  },
  {
    path: "/me/*", // Direct path for teachers
    element: <App />,
    children: [
      {
        // Nested route for profile (e.g., /me/12)
        path: ":encryptedProfileId/*",
        element: <ProfileRouters />,
      },
      {
        // Redirect /me to /dashboard
        path: "*",
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
  {
    path: "*", // Redirect all other routes to the dashboard
    element: <Navigate to="/dashboard" />,
  },
];
export const TeacherRoutes = [
  {
    path: "/dashboard/*",
    element: <App />,
    children: [{ path: "*", element: <ClassRouters /> }],
  },
  {
    path: "/me/*", // Direct path for teachers
    element: <App />,
    children: [
      {
        // Nested route for profile (e.g., /me/12)
        path: ":encryptedProfileId/*",
        element: <ProfileRouters />,
      },
      {
        // Redirect /me to /dashboard
        path: "*",
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
  {
    path: "*", // Redirect all other routes to the dashboard
    element: <Navigate to="/dashboard" />,
  },
];
