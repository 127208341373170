import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import CacheBuster from "react-cache-buster";
import version from "../package.json";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </CacheBuster>
);
