import { axios } from "../../../lib/axios";
// import { ClassTypes } from "../types";

export const CreateStudentPaymentReq = async ({ data }) => {
  // console.log("data", data);

  const response = await axios.post(`/student_payment`, data); // Use stage_name key
  // console.log("response", response);

  return response;
};
