import { axios } from "../../../lib/axios";

export const deletedGroupReq = async () => {
  const response2 = await axios.get(
    `/courses?isDeleted=true&relations[]=subject&relations[]=subject.stage`
  );
  // console.log(response2);

  // Check if deleted_At exists in each dictionary and add isDeleted property
  const dataWithIsDeleted = response2.data.array.map((item: any) => ({
    ...item,
    isClassDeleted: !!item.subject.stage.deletedAt,
    isSubjectDeleted: !!item.subject.deletedAt, // Set isDeleted to true if deleted_At exists, otherwise false
  }));

  // console.log(dataWithIsDeleted);
  return dataWithIsDeleted;
};
