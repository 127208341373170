import { axios } from "../../../lib/axios";
// import { GroupsType } from "../types";

export const GetStudentPayment = async ({ GroupStudentId }: any) => {
  // console.log(GroupId, StudentId);
  const response = await axios.get(
    `student_payment?studentGroupId=${GroupStudentId}`
  );
  // console.log("response", response);
  return response.data;
};

// done types
