import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { EditGroupReq } from "../api/editGroup";
import CloseIcon from "@mui/icons-material/Close";
import dayjs, { Dayjs } from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

const EditSingleGroupModal = ({ editOpen, handleEditClose, groupData }) => {
  const { encryptedGrouptId } = useParams();

  const secretGroupKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;

  const GroupId = decryptionFun(encryptedGrouptId, secretGroupKey);

  const inputEditSchema = z.object({
    groupName: z.string().nonempty("الرجاء ادخال اسم الكروب"),
    price: z.string().nonempty("الرجاء ادخال سعر الكورس"),
    startDate: z.string().nonempty(),
    endDate: z.string().nonempty(),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm({
    resolver: zodResolver(inputEditSchema),
  });

  const queryClient = useQueryClient();

  const { mutateAsync: editGroupMutate } = useMutation(EditGroupReq, {
    onSuccess: () => {
      queryClient.invalidateQueries(["teacher"]);
      queryClient.invalidateQueries(["singleGroupData", GroupId]);
    },
  });

  useEffect(() => {
    setValue("groupName", groupData?.name);
    setValue("price", groupData?.fee);
    setValue("startDate", groupData?.date_start);
    setValue("endDate", groupData?.date_end);
    clearErrors();
  }, [groupData]);

  const onEditSubmit = async (data: any) => {
    try {
      const formData = {
        name: data.groupName,
        fee: data.price,
        date_start: data?.startDate,
        date_end: data?.endDate,
      };

      if (encryptedGrouptId) {
        if (GroupId) {
          await editGroupMutate({ GroupId, formData });
        }
      }
      
      handleEditClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Modal
      open={editOpen}
      onClose={handleEditClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styleClasses}>
        <div className="flex justify-between items-center font-mainfont pb-6">
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={editOpen ? "long-menu" : undefined}
              aria-expanded={editOpen ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleEditClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="font-mainfont">تعديل</div>
        </div>
        <form onSubmit={handleSubmit(onEditSubmit)}>
          <div className="py-2 flex items-center justify-between">
            <div className="w-9/12 xsm:w-8/12">
              <Controller
                control={control}
                name="groupName"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    id="groupName"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="ادخل اسم الكروب"
                    error={!!errors.groupName}
                    InputLabelProps={{
                      style: {
                        textAlign: "right", 
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", 
                        direction: "rtl", 
                      },
                    }}
                    style={{
                      direction: "rtl", 
                      textAlign: "right", 
                    }}
                  />
                )}
              />
              {errors.groupName && (
                <div className="text-right text-xs text-red-600 pt-1">
                  {errors.groupName.message?.toString()}
                </div>
              )}
            </div>
            <div className="font-mainfont">: اسم الكروب</div>
          </div>

          <div className="py-2 flex items-center justify-between">
            <div className="w-9/12 xsm:w-8/12">
              <Controller
                control={control}
                name="price"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    id="price"
                    defaultValue={groupData?.price}
                    onChange={(e) => field.onChange(e.target.value)}
                    value={field.value}
                    placeholder="ادخل سعر الكورس"
                    error={!!errors.price}
                    InputLabelProps={{
                      style: {
                        textAlign: "right", 
                      },
                    }}
                    InputProps={
                      {
                        
                      }
                    }
                    style={{
                      direction: "rtl", 
                      textAlign: "right", 
                    }}
                  />
                )}
              />
              {errors.price && (
                <div className="text-right text-xs text-red-600 pt-1">
                  {errors.price.message?.toString()}
                </div>
              )}
            </div>
            <div className="font-mainfont">: سعر الكورس</div>
          </div>

          <div className="py-2 flex items-center justify-between">
            <div className="w-9/12 xsm:w-8/12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => (
                      <DatePicker
                        className="w-full"
                        value={dayjs(field.value)}
                        onChange={(date: Dayjs | null) => {
                          return field.onChange(
                            date.add(3, "hour")?.toISOString().slice(0, 10) ||
                              null
                          );
                        }}
                        format="DD-MM-YYYY"
                        minDate={dayjs("2000-01-01")}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {errors.startDate && (
                <div className="text-right text-xs text-red-600 pt-1">
                  {errors.startDate.message?.toString()}
                </div>
              )}
            </div>
            <div className="font-mainfont">: تاريخ البدء</div>
          </div>
          <div className="py-2 flex items-center justify-between">
            <div className="w-9/12 xsm:w-8/12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field }) => (
                      <DatePicker
                        className="w-full"
                        value={dayjs(field.value)}
                        onChange={(date: Dayjs | null) => {
                          return field.onChange(
                            date.add(3, "hour")?.toISOString().slice(0, 10) ||
                              null
                          );
                        }}
                        format="DD-MM-YYYY"
                        minDate={dayjs("2000-01-01")}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {errors.endDate && (
                <div className="text-right text-xs text-red-600 pt-1">
                  {errors.endDate.message?.toString()}
                </div>
              )}
            </div>
            <div className="font-mainfont">: تاريخ الانتهاء</div>
          </div>

          <div className="flex font-mainfont pt-6">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onEditSubmit)}
            >
              <div className="font-mainfont">اضافة</div>
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default EditSingleGroupModal;
