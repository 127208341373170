import { axios } from "../../../lib/axios";

export const GroupArchiveDelete = async ({ id, enteredId }) => {
  const requestBody = {
    entity: "course",
    id: enteredId,
  };
  const response = await axios.delete(`/delete-archived/${id}`, {
    data: requestBody,
  });
  return response;
};
// done types
