import { axios } from "../../../lib/axios";
import { CreateStudentType } from "../../students/types";

export const DeleteStudentPaymentReq = async ({ id }) => {
  console.log(id);

  const response = await axios.delete(`/student_payment/${id}`);

  return response.data;
};
