import { axios } from "../../../lib/axios";

interface EditClassReqParams {
  id: string | number;
  stage_name: string;
}

export const EditClassReq = async ({ id, stage_name }: EditClassReqParams) => {
  const dataReq = { stage_name };

  const response = await axios.patch(`/stages/${id}`, dataReq);
  return response;
};
