import { Card, CardContent } from "@mui/material";
import CountUp from "react-countup/build";
const colorPalette = [
  "#D8BFD8",
  "#FA8B3A",
  "#FED142",
  "#2DD683",
  "#00B3FF",
  "#FF7276",
  "#8E44AD",
  "#3498DB",
];
export const MetaDataCard = ({ index, title, data }: any) => {
  // Check if data is a number
  const isNumber = typeof data === "number";
  // console.log(data);

  return (
    <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-3">
      <div>
        <Card className={`bg-white rounded-md h-20 w-full`}>
          <CardContent className="flex items-center h-full relative">
            <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center w-full">
              <div className="font-mainfont font-bold">{title}</div>
              <div className="font-mainfont font-bold">
                {isNumber ? <CountUp end={Number(data)} /> : data}
              </div>
            </div>
            <div
              style={{
                backgroundColor: colorPalette[index],
              }}
              className={`absolute right-0 top-0 h-full pr-3 rounded-tr-md rounded-br-md`}
            ></div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
