import {
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Waveform } from "@uiball/loaders";
import { Fragment, Key, useEffect, useState } from "react";
import { encryptionFun } from "../../../lib/secretUrl";
import { useNavigate, useParams } from "react-router-dom";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { decryptionFun } from "../../../lib/secretUrl";
import { CreateTeacherPayment } from "../../teachers/components/creteTeacherPayment";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
import { GetSingleGroup } from "../api/getSingleGroup";
import { GetCurrentTeacher } from "../api/getCurrentTeacher";
import { CreateTeacherPaymentReq } from "../api/createTeacherPayment";
import { ChangeTeacher } from "./changeTeacher";
import { GetTeacherPayment } from "../api/getTeacherPayments";
import { DeleteTeacherPayment } from "./deleteTeacherPayment";
import { EditTeacherPayment } from "./editTeacherPayment";
import { GetGroupStudent } from "../api/getGroupStudent";
import useStudentTotalPrice from "../hooks/useStudentTotalPrice";

function Row(props: { row: any }) {
  const { row } = props;

  const { checkAccess } = useAuthorization();
  const [open, setOpen] = useState(false);
  // const [totalTeacher, setTotalTeacher] = useState(0);
  // const [totalCouldPayToTeacher, setTotalCouldPayToTeacher] = useState(0);
  // const [paymentsIsLoading, setPaymentsIsLoading] = useState(true);
  const navigate = useNavigate();
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const { encryptedGrouptId } = useParams();

  const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);

  const { data: teacherPayment } = useQuery(
    ["getTeacherPayment", row?.teacherId, row?.courseId],
    () =>
      GetTeacherPayment({
        TeacherId: row?.teacherId,
        GroupId: row?.courseId,
      }),
    {
      enabled: !!row?.teacherId && !!row?.courseId,
    }
  );

  const { data: groupStudentData, isSuccess: isGroupStudentSuccess } = useQuery(
    ["GetGroupStudent", GroupId],
    () => GetGroupStudent(GroupId),
    {
      enabled: !!GroupId,
    }
  );

  const secretKey = process.env.REACT_APP_TEACHER_ENCRYPTION_KEY as string;

  const handleTeacher = () => {
    const decId = encryptionFun(row?.teacherId, secretKey);
    navigate(`/teachers/${decId}`);
  };

  const handleSetOpen = async (id: number) => {
    setOpen(!open);
  };

  const {
    totalTeacher,
    totalCouldPayToTeacher,
    isLoading: paymentsIsLoading,
    refetchData,
  } = useStudentTotalPrice(groupStudentData);
  // const handleStudentTotalPrice = async (groupStudentData) => {
  //   try {
  //     let total = 0;
  //     let totalCouldPayToTeacher = 0;

  //     const calculateTotal = async (results: any[]) => {
  //       for (const result of results) {
  //         const currentResponse = await GetCurrentStudent({
  //           GroupId: result.courseID,
  //           StudentId: result.studentID,
  //         });

  //         totalCouldPayToTeacher +=
  //           (Number(currentResponse.totalPayment) * 80) / 100;
  //         if (currentResponse.totalFee !== 0) {
  //           total += (Number(currentResponse.totalFee) * 80) / 100;
  //         } else {
  //           total += 0;
  //         }
  //       }

  //       setTotalTeacher(total);
  //       setTotalCouldPayToTeacher(totalCouldPayToTeacher);
  //     };

  //     calculateTotal(groupStudentData?.array);
  //     setPaymentsIsLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching teacher data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (isGroupStudentSuccess) {
  //     handleStudentTotalPrice(groupStudentData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isGroupStudentSuccess]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const queryClient = useQueryClient();

  const { mutateAsync: CreateTeacherPaymentMutate } = useMutation(
    CreateTeacherPaymentReq,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getTeacherPayment",
          row?.teacherId,
          row?.courseId,
        ]);
        queryClient.invalidateQueries(["GetGroupStudent", GroupId]);
        refetchData();
      },
    }
  );

  const createPay = async (validDate: any, setItsBegger: any) => {
    try {
      if (
        Number(validDate.amount_paid) >
        Number(
          totalTeacher - (row?.allTeacherPayment ? row?.allTeacherPayment : 0)
        )
      ) {
        setItsBegger(true);
        return false;
      } else {
        await CreateTeacherPaymentMutate({ body: validDate });
        return true;
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      throw error;
    }
  };

  return (
    <>
      <Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          {checkAccess({
            allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
          }) ? (
            <TableCell align="center">
              <div className="flex justify-center">
                <div className="p-1">
                  <CreateTeacherPayment
                    teacherId={row?.teacherId}
                    createPay={createPay}
                  />
                </div>
                <div className="p-1">
                  <Button
                    onClick={() => handleTeacher()}
                    variant="contained"
                    color="primary"
                  >
                    <div className="font-mainfont">عرض الملف الشخصي</div>
                  </Button>
                </div>
              </div>
            </TableCell>
          ) : (
            ""
          )}
          {paymentsIsLoading === false ? (
            <>
              <TableCell align="center">
                {row?.allTeacherPayment <= totalCouldPayToTeacher ? (
                  <div
                    className="font-mainfont text-base"
                    style={{
                      color:
                        row?.allTeacherPayment <= totalCouldPayToTeacher
                          ? "inherit"
                          : "red",
                    }}
                  >
                    {formatNumberWithCommas(
                      totalCouldPayToTeacher - row?.allTeacherPayment
                    )}
                  </div>
                ) : (
                  <Tooltip
                    title={
                      <span style={{ fontSize: "14px" }}>
                        المبلغ المدفوع للاستاذ اعلى من المبلغ المستلم من قبل
                        الطلاب
                      </span>
                    }
                  >
                    <div
                      className="font-mainfont text-base"
                      style={{
                        color:
                          row?.allTeacherPayment <= totalCouldPayToTeacher
                            ? "inherit"
                            : "red",
                      }}
                    >
                      {formatNumberWithCommas(
                        totalCouldPayToTeacher - row?.allTeacherPayment
                      )}
                    </div>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {formatNumberWithCommas(
                    totalTeacher -
                      (row?.allTeacherPayment ? row?.allTeacherPayment : 0)
                  )}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {formatNumberWithCommas(
                    row?.allTeacherPayment ? row?.allTeacherPayment : 0
                  )}
                </div>
              </TableCell>{" "}
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {formatNumberWithCommas(totalTeacher ? totalTeacher : 0)}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {row?.teacherAddress}
                </div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">
                  {row?.teacherPhone}
                </div>
              </TableCell>
              <TableCell align="center" width={50}>
                <div className="font-mainfont flex items-center w-full justify-between text-base p-4">
                  <div className="flex justify-center">{row?.userFullName}</div>
                  <div className="flex justify-center">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleSetOpen(row?.id)}
                    >
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </div>
                </div>
              </TableCell>
            </>
          ) : (
            ""
          )}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit className="py-10">
              {teacherPayment?.array?.length > 0 ? (
                <>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 150 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center"></TableCell>
                          {/* <TableCell align="center">التاريخ</TableCell> */}
                          <TableCell align="center">تاريخ الوصل</TableCell>
                          <TableCell align="center">المبلغ</TableCell>
                          <TableCell align="center">اسم الوصل</TableCell>
                          {/* <TableCell align="center">الصورة</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {teacherPayment?.array?.map(
                          (payment: any, index: any) => (
                            <TableRow
                              key={index}
                              sx={{ "& > *": { borderBottom: "unset" } }}
                            >
                              <TableCell align="center" className="flex">
                                <div className="flex justify-center">
                                  <DeleteTeacherPayment
                                    payment={payment}
                                    row={row}
                                    GroupId={GroupId}
                                    refetchData={refetchData}
                                  />
                                  <EditTeacherPayment
                                    payment={payment}
                                    row={row}
                                    GroupId={GroupId}
                                    refetchData={refetchData}
                                  />
                                </div>
                              </TableCell>
                              {/* <TableCell align="center">
                                <div>{payment.date}</div>
                              </TableCell> */}
                              <TableCell align="center">
                                {payment.invoiceDate != null ? (
                                  <div>
                                    {
                                      new Date(payment.invoiceDate)
                                        .toISOString()
                                        .split("T")[0]
                                    }
                                  </div>
                                ) : (
                                  <div>لايوجد معلومات</div>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <div>
                                  {formatNumberWithCommas(
                                    Number(payment.amount_paid)
                                  )}
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                {payment.invoiceName != null ? (
                                  <div>{payment.invoiceName}</div>
                                ) : (
                                  <div>لايوجد معلومات</div>
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <div className="flex justify-center p-4">
                  <div>لايوجد اي دفعات </div>
                </div>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    </>
  );
}

export const GroupTeacherTable = () => {
  const { encryptedGrouptId } = useParams();
  const { checkAccess } = useAuthorization();

  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);

  const { data: teacherData } = useQuery(
    ["group-teacher-list", GroupId],
    () => GetSingleGroup(GroupId),
    {
      enabled: !!GroupId,
    }
  );

  const { data: teacherList, isLoading } = useQuery(
    ["group-teacher-list", teacherData?.id, teacherData?.teacherId],
    () =>
      GetCurrentTeacher({
        GroupId: teacherData?.id,
        teacherId: teacherData?.teacherId,
      }),
    {
      enabled: !!teacherData?.id && !!teacherData?.teacherId,
    }
  );

  return (
    <div className="pt-5">
      {checkAccess({
        allowedRoles: [ROLES.TEACHER],
      }) ? (
        <>
          <div className="flex flex-col px-5">
            <div className="flex justify-end items-center py-6">
              <div className="font-mainfont text-3xl">المدرس</div>
            </div>
            <div className="border-b-2 border-mainLine"></div>
          </div>
        </>
      ) : (
        ""
      )}

      {checkAccess({
        allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
      }) ? (
        <>
          <div className="flex flex-col px-5">
            <div className="flex justify-between items-center py-6">
              <div>
                <ChangeTeacher />
              </div>
              <div className="font-mainfont text-3xl">المدرس</div>
            </div>
            <div className="border-b-2 border-mainLine"></div>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="p-3">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {checkAccess({
                  allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
                }) ? (
                  <>
                    <TableCell align="center">
                      <div className="font-mainfont text-base"></div>
                    </TableCell>
                  </>
                ) : (
                  ""
                )}
                <TableCell align="center">
                  <div className="font-mainfont text-base">
                    المبلغ الممكن دفعه للاستاذ
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">
                    اجمالي المبلغ المتبقي للاستاذ
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">
                    اجمالي المبلغ المدفوع للاستاذ
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">
                    اجمالي المبلغ المستحق
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">عنوان السكن</div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">رقم الهاتف</div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-mainfont text-base">الاسم</div>
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              ""
            ) : (
              <TableBody className="flex w-full justify-center items-center">
                {[teacherList]?.map(
                  (row: any, index: Key | null | undefined) => (
                    <Row key={index} row={row} />
                  )
                )}
              </TableBody>
            )}
          </Table>
          {isLoading ? (
            <div className="flex w-full justify-center items-center p-16">
              <Waveform size={50} speed={2} color="black" />
            </div>
          ) : (
            ""
          )}
        </TableContainer>
      </div>
    </div>
  );
};

// TODO: fix render cell in table teacher
