import { AuthRoutes } from "../features/auth";
import { Navigate } from "react-router-dom";

export const publicRoutes = [
  {
    path: "/auth/*", // Match any route under /auth
    element: <AuthRoutes />,
  },
  {
    path: "*", // Catch-all route for all other paths
    element: <Navigate to="/auth" />, // Redirect to /auth
  },
];
