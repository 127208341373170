import { useQuery } from "react-query";
import { Breadcrumbs } from "@mui/material";
import { Waveform } from "@uiball/loaders";
import { GroupMetaData } from "./groupMetaData";
import { useNavigate, useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetsingleSubjectData } from "../../subject/api/getSingleSubject";
import { GetSingleGroup } from "../api/getSingleGroup";
import { EditSingleGroup } from "./editSingleGroup";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { GetSingleClass } from "../api/getSingleStage";
import { DeleteSingleGroup } from "./deleteGroup";

export const SingleGroupData = () => {
  const navigate = useNavigate();
  const { checkAccess } = useAuthorization();
  const { encryptedClassId, encryptedSubjectId, encryptedGrouptId } =
    useParams();

  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const secretSubjectKey = process.env
    .REACT_APP_SUBJECT_ENCRYPTION_KEY as string;
  const secretGroupKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;

  const ClassId = decryptionFun(encryptedClassId, secretClassKey);
  const GroupId = decryptionFun(encryptedGrouptId, secretGroupKey);
  const SubjectId = decryptionFun(encryptedSubjectId, secretSubjectKey);

  const { data: groupData, isLoading } = useQuery(
    ["singleGroupData", GroupId],
    () => GetSingleGroup(GroupId),
    {
      enabled: !!GroupId,
    }
  );

  const { data: classData } = useQuery(
    ["singleClassData", ClassId],
    () => GetSingleClass(ClassId),
    {
      enabled: !!ClassId,
    }
  );

  const { data: subjectData } = useQuery(
    ["singleSubjectData", SubjectId],
    () => GetsingleSubjectData(SubjectId),
    {
      enabled: !!SubjectId,
    }
  );

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center p-16">
          <Waveform size={40} speed={2} color="black" />
        </div>
      ) : (
        <>
          <div className="pl-5 flex justify-between">
            <Breadcrumbs aria-label="breadcrumb">
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() => navigate(`/dashboard`)}
              >
                الصفوف
              </div>
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() => navigate(`/dashboard/${encryptedClassId}`)}
              >
                {classData?.stage_name}
              </div>
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() =>
                  navigate(
                    `/dashboard/${encryptedClassId}/${encryptedSubjectId}`
                  )
                }
              >
                {subjectData?.subject_name}
              </div>
              <div>
                <div className="font-mainfont sm:text-sm xsm:text-xs md:text-md">
                  {groupData?.name}
                </div>
              </div>
            </Breadcrumbs>
          </div>

          {checkAccess({
            allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
          }) ? (
            <div className="flex flex-col px-5">
              <div className="flex justify-between items-center py-5">
                <div className="flex justify-start gap-4">
                  {checkAccess({
                    allowedRoles: [ROLES.SUPERADMIN],
                  }) && <DeleteSingleGroup />}
                  <EditSingleGroup groupData={groupData} />
                </div>
                <div className="font-mainfont text-3xl">{groupData?.name}</div>
              </div>
              <div className="border-b-2 border-mainLine"></div>
            </div>
          ) : (
            <div className="flex justify-end p-5 items-center">
              <div className="font-mainfont text-3xl">{groupData?.name}</div>
            </div>
          )}

          {checkAccess({
            allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
          }) ? (
            <GroupMetaData />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
