import { axios } from "../../../lib/axios";

export const GetGroupsSingleTeacher = async (id: any) => {
  const response = await axios.get(
    `/teacher/${id}?relations[]=course&relations[]=user&relations[]=teacher_payment`
  );
  return response.data;
};

// done types
