import { Waveform } from "@uiball/loaders";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetSingleTeacherCourseDataReq } from "../api/getSingleTeacherCourseData";
import { TeacherGroupCard } from "./teacherGroupCard";

export const TeacherProfileCourseList = () => {
  const [teacherCardData, setTeacherCardData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const { encryptedTeacherId } = useParams();
  const secretKey = process.env.REACT_APP_TEACHER_ENCRYPTION_KEY as string;

  const fetchTeacherData = async () => {
    if (encryptedTeacherId) {
      try {
        const id = decryptionFun(encryptedTeacherId, secretKey);
        const response = await GetSingleTeacherCourseDataReq(id); // Pass the id as an object property
        // console.log("response", response);

        const teacherGroup = response.course;
        setTeacherCardData(teacherGroup);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching teacher data:", error);
      }
    }
  };

  useEffect(() => {
    fetchTeacherData(); // Call the function without passing parameters
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log("groupData", teacherCardData);

  return (
    <>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={100} speed={2} color="black" />
          </div>
        ) : (
          <div>
            {teacherCardData?.length > 0 ? (
              <div className="flex flex-wrap justify-end pt-2">
                {teacherCardData?.map((groupData: any, index: any) => (
                  <div className="w-full md:w-1/2 lg:w-1/3 p-2" key={index}>
                    <TeacherGroupCard
                      fetchData={fetchTeacherData}
                      teacherGroupData={groupData}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center p-5">
                <div>المدرس الحالي لا يملك كروبات</div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
