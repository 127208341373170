import storage from "../utils/storage";
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import {
  loginWithEmailAndPassword,
  getUser,
  LoginCredentialsDTO,
  AuthUser,
} from "../features/auth";

type AuthContextType = {
  user: AuthUser | null;
  setUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
  login: (credentials: LoginCredentialsDTO) => Promise<void>;
  logout: () => void;
  isLoading: boolean;
};

const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => {},
  login: () => Promise.resolve(),
  logout: () => {},
  isLoading: true,
});

async function handleUserResponse(data: any) {
  const { accessToken, user } = data;
  storage.setToken(accessToken);
  return user;
}
async function loadUser() {
  if (storage.getToken()) {
    try {
      const response = await getUser();
      if (response && response.data) {
        // console.log(response);
        let userData: {
          id: any;
          role: string;
          teacherId?: any;
          schoolId: string;
        } = {
          id: response.data.id,
          role: response.data.role,
          schoolId: response.data.schoolId,
        };
        if (response.data.role === "teacher") {
          userData.teacherId = response.data.teacherId;
        }
        return userData;
      }
      return null;
    } catch (error) {
      console.error("Error loading user:", error);
      return null;
    }
  }
  return null;
}
async function loginFn(credentials: LoginCredentialsDTO): Promise<void> {
  const response = await loginWithEmailAndPassword(credentials);
  // console.log("responsedata", response["data"]);

  await handleUserResponse(response["data"]);
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin as unknown as string);
}

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // console.log(user);

  useEffect(() => {
    async function initializeUser() {
      const loadedUser = await loadUser();
      setUser(loadedUser);
      setIsLoading(false);
    }

    initializeUser();
  }, []);
  // console.log("user", user);

  return (
    <AuthContext.Provider
      value={{
        user: user,
        setUser: setUser,
        login: loginFn,
        logout: logoutFn,
        isLoading: isLoading,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
