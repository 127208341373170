import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Fragment } from "react";
import { encryptionFun } from "../../../lib/secretUrl";
import { Link } from "react-router-dom";
function Row(props: { row: any }) {
  const { row } = props;
  const secretKey = process.env.REACT_APP_TEACHER_ENCRYPTION_KEY as string;

  const HandleNavigate = () => {
    const id = encryptionFun(row.id, secretKey);
    return `/teachers/${id}`;
  };

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">
          <Link to={HandleNavigate()}>
            <Button variant="contained" color="primary">
              <div className="font-mainfont">عرض الملف الشخصي</div>
            </Button>
          </Link>
        </TableCell>
        <TableCell align="center">{row.specialty}</TableCell>
        <TableCell align="center">{row.address}</TableCell>
        <TableCell align="center">{row.phone}</TableCell>
        <TableCell align="center">
          <div className="flex justify-center items-center">
            {row.user.fullName}
          </div>
        </TableCell>
      </TableRow>
      <TableRow></TableRow>
    </Fragment>
  );
}

export const TeacherTable = ({ teacherData }: any) => {
  return (
    <div className="pt-5">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <div className="font-mainfont text-base"></div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">الاختصاص</div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">عنوان السكن</div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">رقم الهاتف</div>
              </TableCell>
              <TableCell align="center">
                <div className="font-mainfont text-base">اسم المدرس</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teacherData.length > 0
              ? teacherData?.map((row: any, index: number) => (
                  <Row key={index} row={row} />
                ))
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
      {teacherData.length > 0 ? (
        ""
      ) : (
        <div className="flex justify-center p-5">
          <div>لا يوجد مدرسيين</div>
        </div>
      )}
    </div>
  );
};
