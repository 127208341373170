// import { TeacherProfileCourseList } from "../components/teacherProfileCourseList";
// import { TeacherProfile } from "../components/teacherProfile";

import { useEffect } from "react";
import { AdminProfile } from "../components/adminProfile";

// import { StudentProfile } from "../components/studentProfile";
// import { StudentProfileCourseList } from "../components/studentProfileCourseList";

export const AdminProfileWrapper = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="pt-5">
      <div>
        {/* <TeacherProfile /> */}
        {/* student profile */}
        <AdminProfile />
        {/* <div className="pt-3 p-5">
          <>
            <div className="flex justify-between items-center mb-2">
              <div></div>
              <div>
                <div className="font-mainfont text-3xl">الصفوف</div>
              </div>
            </div>
            <div className="border-b-2 border-mainLine pt-2"></div>
          </>
          <StudentProfileCourseList />
        </div> */}
      </div>
    </div>
  );
};
