import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { Waveform } from "@uiball/loaders";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Fragment, Key } from "react";
import Collapse from "@mui/material/Collapse";
import { deletedGroupReq } from "../api/deletedGroupReq";
import { UndeletedGroup } from "./undeletedGroup";
import { useQuery } from "react-query";

function Row(props: { row: any }) {
  const { row } = props;
  // console.log(row);

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">
          <UndeletedGroup row={row} />
        </TableCell>
        {/* <TableCell align="center">{row.parent_phone}</TableCell> */}
        {/* <TableCell align="center">{row.address}</TableCell> */}
        {/* <TableCell align="center">{row.phone}</TableCell> */}
        <TableCell align="center">
          <div className="flex justify-center items-center">{row.name}</div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export const DeletedGroupList = () => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const { data: courseList, isLoading } = useQuery(["deleted-group-list"], () =>
    deletedGroupReq()
  );

  const handleChange = () => {
    setChecked((prev) => !prev);
    setOpen(!open);
  };

  return (
    <>
      <div className="flex justify-end items-center mb-2">
        <div className="flex cursor-pointer" onClick={handleChange}>
          <div className="font-mainfont text-2xl">الكروبات</div>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
      </div>
      <div className="border-b-2 border-mainLine pt-3"></div>
      <Collapse in={checked}>
        <div className="pt-5">
          {isLoading ? (
            <div className="flex justify-center items-center p-16">
              <Waveform size={40} speed={2} color="black" />
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell /> */}
                    <TableCell align="center">
                      <div className="font-mainfont text-base"></div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="font-mainfont text-base">الاسم</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {courseList.length > 0
                    ? courseList?.map(
                        (row: any, index: Key | null | undefined) => (
                          <Row key={index} row={row} />
                        )
                      )
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {isLoading ? (
            ""
          ) : courseList.length > 0 ? (
            ""
          ) : (
            <div className="flex justify-center p-5">
              <div>لا يوجد مواد</div>
            </div>
          )}
        </div>
      </Collapse>
    </>
  );
};
