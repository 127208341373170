import { axios } from "../../../lib/axios";

export const GetSingleStudentData = async (id: number | null) => {
  const response = await axios.get(
    `/students/${id}/courses?relations[]=student_group`
  );
  const secountResponse = await axios.get(`/students/courses`);

  const singleStudent = secountResponse.data.find(
    (single: any) => single.id === id
  );
  const nonNullCourses = singleStudent.student_group
    .filter((result: any) => result.course !== null)
    .map((result: any) => result.course);

  return { response: response.data, secountResponse: nonNullCourses };
};
