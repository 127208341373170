import { decryptionFun } from "../../../lib/secretUrl";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PersonalInformation } from "../components/personalInformation";
import { getCurrentUser } from "../api/getCurrentUser";

export const ProfileWrapper = () => {
  const { encryptedProfileId } = useParams();
  const secretKey = process.env.REACT_APP_ME_ENCRYPTION_KEY as string;
  const [userInformation, setUserInfor] = useState<any>();
  const [Isloading, setIsLoading] = useState(true);

  const userInfo = async () => {
    if (encryptedProfileId) {
      const id = decryptionFun(encryptedProfileId, secretKey);
      const response = await getCurrentUser(id);
      setUserInfor(response);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    userInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="p-10">
        <div>
          {Isloading ? (
            ""
          ) : (
            <PersonalInformation userInformation={userInformation} />
          )}
        </div>
        {/* <div>
          <div className="flex justify-between"></div>
          <div className="flex flex-col">
            <div className="flex justify-end items-center">
              <div className="flex font-mainfont py-6">
                <Button type="submit" variant="contained" color="primary">
                  <div className="font-mainfont">تعديل المعلومات الشخصية</div>
                </Button>
              </div>
              <div className="font-mainfont text-3xl">
                {user?.userName} : الايميل
              </div>
            </div>
            <div className="border-b-2 border-mainLine"></div>
          </div>
        </div> */}
      </div>
    </>
  );
};
