import { axios } from "../../../lib/axios";

type GetStudentDataProp = {
  studentSearch?: string;
};

export const GetStudentDataReq = async (props?: GetStudentDataProp) => {
  const response = await axios.get(`/students/courses`, {
    params: {
      search: props?.studentSearch,
    },
  });

  return response.data;
};
