import { axios } from "../../../lib/axios";

import { UserResponse } from "../types";

export type LoginCredentialsDTO = {
  userName: string;
  password: string;
};

export const loginWithEmailAndPassword = (data: LoginCredentialsDTO) => {
  // console.log(data);

  return axios.post("/auth/signin", data);
};

// done with types
