import { Route, Routes } from "react-router-dom";
import { SingleGroupWrapper } from "./singleGroupWrapper";
// import { SingleClassWrapper } from "./singleClassWrapper";
// import { SingleSubjectWrapper } from "../../subject/routes/SingleSubjectWrapper";

export const SingleGroupRoutes = () => {
  return (
    <Routes>
      <Route index element={<SingleGroupWrapper />} />
      {/* <Route path=":encryptedSubjectId" element={<p>single Group wrapper</p>} /> */}
    </Routes>
  );
};
