import { useRoutes, RouteObject } from "react-router-dom";
import { useAuth } from "../lib/auth";
import { SuperAdminRoutes } from "./protected";
import { AdminRoutes } from "./protected";
import { TeacherRoutes } from "./protected";
import { publicRoutes } from "./public";
import { Loading } from "../components/elements/loading";

export const AppRoutes = () => {
  const { user, isLoading } = useAuth();

  let routes: RouteObject[] = [];
  // console.log(user?.role);

  if (!isLoading)
    switch (user?.role) {
      case "superadmin":
        routes = SuperAdminRoutes;
        break;
      case "admin":
        routes = AdminRoutes;
        break;
      case "teacher":
        routes = TeacherRoutes;
        break;
      default:
        routes = publicRoutes;
    }

  const commonRoutes = [{ path: "*", element: <Loading /> }];

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
