import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditStudentGroupButton } from "./editStudentGroupButton";
import { AddStudent } from "./addStudent";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetGroupStudent } from "../api/getGroupStudent";
import { Waveform } from "@uiball/loaders";
import { GetCurrentStudent } from "../api/getStudent";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
import { GetStudentPayment } from "../api/getStudentPayment";
import { DeleteStudentPayment } from "./deleteStudentPayment";
import { EditStudentPayment } from "./editStudentPayment";
import { useQuery } from "react-query";

function Row(props: any) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [didPayFee, setDidPayFee] = useState(false);
  const { checkAccess } = useAuthorization();

  const { data: singleStudentData } = useQuery(
    ["get-current-student-table", row?.courseID, row?.studentID],
    () =>
      GetCurrentStudent({
        GroupId: row?.courseID,
        StudentId: row?.studentID,
      }),
    {
      enabled: !!row?.courseID && !!row?.studentID,
    }
  );

  const { data: singleStudentPaymentData } = useQuery(
    ["get-current-student-table", singleStudentData?.studentGroupId],
    () =>
      GetStudentPayment({
        GroupStudentId: singleStudentData?.studentGroupId,
      }),
    {
      enabled: !!singleStudentData?.studentGroupId,
    }
  );

  useEffect(() => {
    singleStudentPaymentData?.array?.map((response: any) => {
      if (response.isBook === true) {
        setDidPayFee(true);
      }
    });
  }, [singleStudentPaymentData]);

  // console.log("singleStudentData", singleStudentData);
  const handleSetOpen = async (id: number) => {
    setOpen(!open);
  };
  // console.log(StudentPayment);

  return (
    <>
      <Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          {checkAccess({
            allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
          }) ? (
            <TableCell align="center">
              <EditStudentGroupButton
                studentGroup={singleStudentData}
                didPayFee={didPayFee}
                row={row}
                studentGroupId={singleStudentData?.studentGroupId}
                // coursePrice={singleStudentData?.totalFee}
              />
            </TableCell>
          ) : (
            ""
          )}
          {/* <TableCell align="center">
                <div className="font-mainfont text-base">
                  {row.student.total_paid_price}
                </div>
              </TableCell> */}
          <TableCell align="center">
            <div className="font-mainfont text-base">
              {didPayFee ? "نعم" : "لا"}
            </div>
          </TableCell>
          <TableCell align="center">
            <div className="font-mainfont text-base">
              {singleStudentData?.courseFee - singleStudentData?.totalFee !==
              0 ? (
                // <CheckIcon />
                <div>نعم</div>
              ) : (
                <div>لا</div>
              )}
            </div>
          </TableCell>
          <TableCell align="center">
            <div className="font-mainfont text-base">
              {formatNumberWithCommas(
                Number(singleStudentData?.totalFee) -
                  Number(
                    singleStudentData?.allStudentPayment
                      ? singleStudentData?.allStudentPayment
                      : 0
                  )
              )}
            </div>
          </TableCell>
          <TableCell align="center">
            <div className="font-mainfont text-base">
              {formatNumberWithCommas(
                singleStudentData?.allStudentPayment
                  ? singleStudentData?.allStudentPayment
                  : 0
              )}
            </div>
          </TableCell>
          <TableCell align="center">
            <div className="font-mainfont text-base">
              {formatNumberWithCommas(Number(singleStudentData?.totalFee))}
            </div>
          </TableCell>
          <TableCell align="center">
            <div className="font-mainfont text-base">
              {singleStudentData?.studentParentPhone}
            </div>
          </TableCell>
          <TableCell align="center">
            <div className="font-mainfont text-base">
              {singleStudentData?.studentAddress}
            </div>
          </TableCell>
          <TableCell align="center">
            <div className="font-mainfont text-base">
              {singleStudentData?.studentPhone}
            </div>
          </TableCell>

          <TableCell align="center" width={50}>
            <div className="font-mainfont flex items-center w-full justify-between text-base p-4">
              {/* {row.student.student_name} */}
              <div className="flex justify-center">
                {singleStudentData?.studentName}
              </div>
              <div className="flex justify-center">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleSetOpen(row.id)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit className="py-10">
              {singleStudentPaymentData?.array?.length > 0 ? (
                <>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 150 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center"></TableCell>
                          {/* <TableCell align="center">التاريخ</TableCell> */}
                          <TableCell align="center">تاريخ الوصل</TableCell>
                          <TableCell align="center">المبلغ</TableCell>
                          <TableCell align="center">اسم الوصل</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {singleStudentPaymentData?.array.map(
                          (payment: any, index: any) => {
                            return payment.isBook === false ? (
                              <TableRow
                                key={index}
                                sx={{ "& > *": { borderBottom: "unset" } }}
                              >
                                <TableCell align="center" className="flex ">
                                  <div className="flex justify-center">
                                    <DeleteStudentPayment
                                      payment={payment}
                                      studentGroupId={
                                        singleStudentData?.studentGroupId
                                      }
                                      row={row}
                                    />
                                    <EditStudentPayment
                                      payment={payment}
                                      studentGroupId={
                                        singleStudentData?.studentGroupId
                                      }
                                      row={row}
                                    />
                                  </div>
                                </TableCell>
                                {/* <TableCell align="center">
                                    <div>{payment.date}</div>
                                    {payment.isBook}
                                  </TableCell> */}
                                <TableCell align="center">
                                  {payment.invoiceDate !== null ? (
                                    <div>
                                      {
                                        new Date(payment.invoiceDate)
                                          .toISOString()
                                          .split("T")[0]
                                      }
                                    </div>
                                  ) : (
                                    <div>لايوجد معلومات</div>
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  <div>
                                    {formatNumberWithCommas(
                                      Number(payment.amount_paid)
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  {payment.invoiceName !== null ? (
                                    <div>{payment.invoiceName}</div>
                                  ) : (
                                    <div>لايوجد معلومات</div>
                                  )}
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <div className="flex justify-center p-4">
                  <div>لايوجد اي دفعات </div>
                </div>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    </>
  );
}

export const GroupStudentTable = () => {
  const { encryptedGrouptId } = useParams();
  const { checkAccess } = useAuthorization();
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;

  const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);

  const { data: StudentData, isLoading } = useQuery(
    ["get-student-group", GroupId],
    () => GetGroupStudent(GroupId)
  );

  return (
    <div className="pt-5">
      <>
        <div className="flex flex-col px-5">
          <AddStudent />
          <div className="border-b-2 border-mainLine"></div>
        </div>
        <div className="p-3">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  {checkAccess({
                    allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
                  }) ? (
                    <TableCell align="center">
                      <div className="font-mainfont text-base"></div>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      هل دفع مبلغ الحجز
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      هل يملك الطالب خصم
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      اجمالي المبلغ المتبقي
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      اجمالي المبلغ المدفوع
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      سعر الكورس للطالب
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">
                      رقم الهاتف الوالدين
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">عنوان السكن</div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="font-mainfont text-base">رقم الهاتف</div>
                  </TableCell>
                  <TableCell align="center" size="medium">
                    <div className="font-mainfont text-base">الاسم</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? ""
                  : StudentData?.array?.length > 0
                  ? StudentData?.array?.map((row, index) => (
                      <Row key={index} row={row} />
                    ))
                  : ""}
              </TableBody>
            </Table>
            {isLoading ? (
              <div className="flex justify-center items-center p-16">
                <Waveform size={100} speed={2} color="black" />
              </div>
            ) : StudentData?.array?.length === 0 ? (
              <div className="flex justify-center p-5">
                <div>لايوجد طلاب</div>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
        </div>
      </>
    </div>
  );
};
