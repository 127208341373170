import { useState } from "react";
import { Box, Button, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GetSingleGroup } from "../api/getSingleGroup";
import { useNavigate, useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { DeleteGroup } from "../api/deleteGroup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "../../../lib/auth";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const DeleteSingleGroup = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { encryptedClassId, encryptedSubjectId, encryptedGrouptId } =
    useParams();

  const queryClient = useQueryClient();

  const [deleteOpen, setDeleteOpen] = useState(false);

  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const secretSubjectKey = process.env
    .REACT_APP_SUBJECT_ENCRYPTION_KEY as string;
  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;

  const groupId = decryptionFun(encryptedGrouptId, secreGrouptKey);
  const subjectId = decryptionFun(encryptedSubjectId, secretSubjectKey);
  const classId = decryptionFun(encryptedClassId, secretClassKey);

  const { mutateAsync: DeleteGroupMutate } = useMutation(DeleteGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getSingleGroup", groupId]);
      queryClient.invalidateQueries(["group-list", classId, subjectId, user]);
    },
  });

  const { data: GroupData } = useQuery(["getSingleGroup", groupId], () =>
    GetSingleGroup(groupId)
  );

  const handleDeleteClick = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onDeleteSubmit = async () => {
    await DeleteGroupMutate({ id: GroupData.id });
    navigate(`/dashboard/${encryptedClassId}/${encryptedSubjectId}`);
    handleDeleteClose();
  };

  return (
    <>
      <Button onClick={handleDeleteClick} variant="contained" color="error">
        <div className="font-mainfont">حذف</div>
      </Button>
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styleClasses}>
          <div className="flex justify-between items-center font-mainfont pb-6">
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={deleteOpen ? "long-menu" : undefined}
                aria-expanded={deleteOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleDeleteClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="font-mainfont">حذف الكروب</div>
          </div>
          <div className="flex justify-end pb-6">
            <div>هل انت متاكد من الكروب</div>
          </div>
          <div className="flex font-mainfont py-4">
            <Button
              type="submit"
              variant="contained"
              color="error"
              onClick={onDeleteSubmit}
            >
              <div className="font-mainfont">تاكيد الحذف</div>
            </Button>
          </div>
          {/* <div className="flex font-mainfont pt-6">
                <Button
                  onClick={onDeleteSubmit}
                  variant="contained"
                  color="primary">
                  <div className="font-mainfont">نعم</div>
                </Button>
              </div> */}
        </Box>
      </Modal>
    </>
  );
};
