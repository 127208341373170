import { axios } from "../../../lib/axios";

type GetTeacherDataProp = {
  teacherSearch?: string;
};

export const GetTeacherDataReq = async (props?: GetTeacherDataProp) => {
  const response = await axios.get<any>("/teacher/courses", {
    params: {
      search: props?.teacherSearch,
    },
  });

  return response.data;
};

export type ResponseInterceptor<T> = {
  array: T[];
  count: number;
};
