import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { encryptionFun } from "../../../lib/secretUrl";
import { EditGroup } from "./editGroup";
import dayjs from "dayjs";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { GetGroupsSingleTeacher } from "../api/getGroupsSingleTeacher";
import { useQuery } from "react-query";

export const GroupCard = ({
  id,
  title,
  price,
  date_start,
  date_end,
  teacherId,
  fetchData,
}: any) => {
  const { checkAccess } = useAuthorization();

  const { encryptedClassId, encryptedSubjectId } = useParams();
  const secretKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const navigate = useNavigate();

  const { data: teacher } = useQuery(["teacher", teacherId], () =>
    GetGroupsSingleTeacher(teacherId)
  );

  const handleClass = (id: number) => {
    const enctyptedGroupId = encryptionFun(id, secretKey);

    navigate(
      `/dashboard/${encryptedClassId}/${encryptedSubjectId}/${enctyptedGroupId}`
    );
  };

  function formatNumberWithCommas(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Card className="bg-white rounded-md ">
      <div className="flex flex-col justify-between">
        <div className="cursor-pointer" onClick={() => handleClass(id)}>
          <div className="mb-1 text-end font-bold p-3 pr-6 text-xl">
            {title}
          </div>
          <div className="border-b-2 border-cardLine w-full"></div>
          <div className="flex flex-col items-end p-6">
            <div className="flex">
              {dayjs(date_start).format("DD-MM-YYYY")}
              <div className="font-semibold pl-1"> : تاريخ البدء</div>
            </div>
            <div className="flex">
              {dayjs(date_end).format("DD-MM-YYYY")}
              <div className="font-semibold pl-1"> : تاريخ الانتهاء</div>
            </div>
            <div className="flex">
              {teacher?.user?.fullName}
              <div className="font-semibold pl-1"> : الاستاذ</div>
            </div>
            <div className="flex">
              {formatNumberWithCommas(price)}
              <div className="font-semibold pl-1"> : السعر</div>
            </div>
          </div>
          <div className="border-b-2 border-cardLine w-full p-3"></div>
        </div>
        {checkAccess({
          allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
        }) ? (
          <EditGroup GroupId={id} teacherId={teacherId} fetchData={fetchData} />
        ) : (
          ""
        )}
      </div>
    </Card>
  );
};
