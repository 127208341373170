import { axios } from "../../../lib/axios";

export const deletedSubjectsReq = async () => {
  const response2 = await axios.get(
    `/subjects?isDeleted=true&relations[]=stage`
  );

  // console.log(response2);

  // Check if deleted_At exists in each dictionary and add isDeleted property
  const dataWithIsDeleted = response2.data.array.map((item: any) => ({
    ...item,
    isDeleted: !!item.stage.deletedAt, // Set isDeleted to true if deleted_At exists, otherwise false
  }));

  // console.log(dataWithIsDeleted);
  return dataWithIsDeleted;
};
