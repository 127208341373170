import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  initialValue?: string; // Add an optional initialValue prop
}

export const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, initialValue, ...other } = props;

  // Use the initialValue prop to set the initial value
  React.useEffect(() => {
    if (initialValue !== undefined) {
      onChange({
        target: {
          name: props.name,
          value: initialValue,
        },
      });
    }
  }, [initialValue, onChange, props.name]);

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});
