import { axios } from "../../../lib/axios";
// import { GroupsType } from "../types";

export const GetCurrentStudent = async ({ StudentId, GroupId }: any) => {
  // console.log(GroupId, StudentId);

  const response = await axios.get(`students/${StudentId}/course/${GroupId}`);
  // console.log("response", response);

  return response.data;
};

// done types
