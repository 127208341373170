import { axios } from "../../../lib/axios";

export const EditSubjectReq = async (data: any) => {
  try {
    const response = await axios.patch(`/subjects/${data?.id}`, {
      subject_name: data?.subject_name,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
