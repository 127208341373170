import { axios } from "../../../lib/axios";
// import { Student } from "../types";

export const EditStudentPaymentReq = async ({ id, data }: any) => {
  // console.log(id, data);

  const response = await axios.patch(`/student_payment/${id}`, data);

  return response;
};
