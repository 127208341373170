import { axios } from "../../../lib/axios";
// import { ClassTypes } from "../types";

export const DeleteStudentFromGroup = async ({ id }) => {
  console.log(id);

  const response = await axios.delete(`/student-group/${id}`);

  return response;
};
