import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import React, { useMemo } from "react";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import { useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetTeacherDataReq } from "../../teachers/api/getTeacherData";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

import { GetSingleGroup } from "../api/getSingleGroup";
import { GetSingleTeacherDataReq } from "../../teachers/api/getSingleTeacherData";
import { EditGroupTeacher } from "../api/editGroupTeacher";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

export const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
const Context = React.createContext({ name: "Default" });

export const ChangeTeacher = () => {
  const { encryptedGrouptId } = useParams();

  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);

  const queryClient = useQueryClient();

  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم تغيير المدرس بنجاح`,
      placement,
    });
  };

  const { data: teacherList } = useQuery(["teacherList"], () =>
    GetTeacherDataReq()
  );

  const { data: singleGroup } = useQuery(["get-single-group", GroupId], () =>
    GetSingleGroup(GroupId)
  );

  const { data: teacher } = useQuery(
    ["get-single-teacher-data", singleGroup?.teacherId],
    () => GetSingleTeacherDataReq(singleGroup?.teacherId)
  );

  const { mutateAsync: editTeacherGroupMutate } = useMutation(
    EditGroupTeacher,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["group-teacher-list", GroupId]);
        openNotification("topLeft");
        handleClose();
      },
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      teacherId: teacher,
    },
  });

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    try {
      const body = {
        teacherId: data?.teacherId?.id,
        date_end: singleGroup.date_end,
        date_start: singleGroup.date_start,
      };

      await editTeacherGroupMutate({ GroupId, body });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  useEffect(() => {
    setValue("teacherId", teacher);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacher]);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <Button onClick={() => handleClick()} variant="contained" color="primary">
        <div className="font-mainfont">تغيير المدرس</div>
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styleClasses}>
          <div className="flex justify-between items-center font-mainfont pb-6">
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="font-mainfont">اضافة دفعة</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12">
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="teacherId"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <Autocomplete
                          id="teacher-autocomplete"
                          value={field?.value || ""}
                          onChange={(_, newValue) => {
                            field.onChange(newValue);
                          }}
                          options={teacherList}
                          getOptionLabel={(teacher: any) =>
                            teacher?.user?.fullName || ""
                          }
                          style={{
                            marginTop: "0px",
                            textAlign: "right",
                          }}
                          renderInput={(params) => (
                            <TextField
                              error={!!errors.teacherId}
                              {...params}
                              dir="ltr"
                              // label={teacher ? teacher.teacher_name : ""} // Display teacher_name as the label
                              variant="outlined"
                            />
                          )}
                        />
                      </>
                    )}
                  />
                  {errors.teacherId && (
                    <p className="text-red-500">المدرس مطلوب</p>
                  )}
                </FormControl>
              </div>
              <div className="font-mainfont">: اسم المدرس</div>
            </div>
            <div className="flex font-mainfont pt-6">
              <Button type="submit" variant="contained" color="primary">
                <div className="font-mainfont">تغيير</div>
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </Context.Provider>
  );
};

// TODO: add error handling, submit doesn't work
