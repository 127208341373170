import { axios } from "../../../lib/axios";

export const deletedStudentReq = async () => {
  const response = await axios.get(`/students?relations[]=student_group`, {
    params: {
      isDeleted: true,
    },
  });

  return response;
};
