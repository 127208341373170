import { useEffect, useState } from "react";
import { ClassList } from "../components/ClassList";
import { CreateClass } from "../components/CreateClass";
import { SingleClass } from "../types";
import { GetClassesReq } from "../api/getClass";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { useQuery } from "react-query";

export const ClassWrapper = () => {
  const { checkAccess } = useAuthorization();

  const { data: classData, isLoading } = useQuery(["classData"], () =>
    GetClassesReq()
  );

  return (
    <div>
      <div className="pt-3 p-5">
        {checkAccess({
          allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
        }) ? (
          <CreateClass />
        ) : (
          ""
        )}
        <ClassList isLoading={isLoading} classData={classData} />
      </div>
    </div>
  );
};
