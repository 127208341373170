import { useForm } from "react-hook-form";
import { z } from "zod";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ChangePassword } from "../api/changePassword";

const inputSchema = z.object({
  oldPassword: z.string(),
  newPassword: z.string(),
});

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const PersonalInformation = ({
  userInformation,
}: {
  userInformation: any;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const handleOpen = () => {
    setOpen(true);
    setValue("currentPassword", "");
    setValue("newPassword", "");
    clearErrors();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    try {
      // Validate the submitted data using the schema
      const validatedData = inputSchema.parse(data);
      const lastData = {
        oldPassword: validatedData.oldPassword,
        newPassword: validatedData.newPassword,
      };
      const response = await ChangePassword(lastData);
      console.log(response);

      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  // console.log("userInformation", userInformation);

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <div>
          <Button
            onClick={() => handleOpen()}
            type="submit"
            variant="contained"
            size="large"
            color="primary">
            <div className="font-mainfont">تغببر كلمة المرور</div>
          </Button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-6">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">نغيير كلمة المرور</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="oldPassword"
                    placeholder="ادخل كلمة المرور الحالية"
                    {...register("oldPassword", { required: true })}
                    error={!!errors.oldPassword}
                    helperText={
                      errors.oldPassword ? (
                        <div className="text-right">
                          الرجاء ادخال كلمة المرور بشكل صحيح
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont"> : كلمة المرور الحالية</div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="newPassword"
                    placeholder="ادخل كلمة المرور الجديدة"
                    {...register("newPassword", { required: true })}
                    error={!!errors.newPassword}
                    helperText={
                      errors.newPassword ? (
                        <div className="text-right">
                          الرجاء ادخال كلمة المرور بشكل صحيح
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont"> : كلمة المرور الجديدة</div>
              </div>
              <div className="flex font-mainfont py-6">
                <Button type="submit" variant="contained" color="primary">
                  <div className="font-mainfont">تغيير</div>
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
        <div className="font-mainfont text-3xl">{userInformation.fullName}</div>
      </div>
      <div className="border-b-2 border-mainLine pt-2"></div>
    </>
  );
};
