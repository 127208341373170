import { useEffect } from "react";
import { TeacherProfile } from "../components/teacherProfile";
import { TeacherProfileCourseList } from "../components/teacherProfileCourseList";

export const TeacherProfileWrapper = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="pt-5">
      <div>
        <TeacherProfile />
        <div className="pt-3 p-5">
          <>
            <div className="flex justify-between items-center mb-2">
              <div></div>
              <div>
                <div className="font-mainfont text-3xl">الكروبات</div>
              </div>
            </div>
            <div className="border-b-2 border-mainLine pt-2"></div>
          </>
          <TeacherProfileCourseList />
        </div>
      </div>
    </div>
  );
};
