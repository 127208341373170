import { axios } from "../../../lib/axios";

export const GetGroupStudent = async (id: any) => {
  const response = await axios.get(
    `/student-group?course_id=${id}&relations[]=student`
  );
  // console.log("response.data", response.data);

  return response.data;
};

// done types
