import { axios } from "../../../lib/axios";

export const changeTeacherPasswordReq = async (data: any) => {
  //   console.log(data);

  const response = await axios.patch(`/auth/change-Password`, data);
  // console.log(response);

  return response;
};
