export function validateAndCleanSearchQuery(query?: string) {
  if (!query) {
    return "";
  }

  // Replace Arabic numbers with English numbers
  const arabicToEnglishNumbers: { [key: string]: string } = {
    "٠": "0",
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
  };

  // Replace Arabic numbers with English numbers
  query = query.replace(
    /[\u0660-\u0669]/g,
    (match) => arabicToEnglishNumbers[match]
  );

  // Remove any invalid characters
  query = query.replace(/[^a-zA-Z\u0600-\u06FF0-9\s]/g, "");

  // Trim whitespace from both ends of the string
  return query.trim();
}
