import { Route, Routes } from "react-router-dom";
import { SingleSubjectWrapper } from "./SingleSubjectWrapper";
import { SingleGroupRoutes } from "../../groups/routes/index";

export const SingleSujectRoutes = () => {
  return (
    <Routes>
      <Route index element={<SingleSubjectWrapper />} />
      <Route path=":encryptedGrouptId/*" element={<SingleGroupRoutes />} />
    </Routes>
  );
};
