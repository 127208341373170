import { axios } from "../../../lib/axios";

export const TeacherArchiveDelete = async ({ id, enteredId }) => {
  const requestBody = {
    entity: "teacher",
    id: enteredId,
  };
  const response = await axios.delete(`/delete-archived/${id}`, {
    data: requestBody,
  });
  return response.data;
};
