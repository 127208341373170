import { TeacherTable } from "../components/teacherTable";
import { CreateTeacher } from "../components/createTeacher";
import { useState } from "react";
import { GetTeacherDataReq } from "../api/getTeacherData";
import { TextField } from "@mui/material";
import { Waveform } from "@uiball/loaders";
import { useQuery } from "react-query";
import { validateAndCleanSearchQuery } from "../../../utils/validateAndCleanSearchQuery";

export const TeacherWrapper = () => {
  const [teacherSearch, setTeacherSearch] = useState("");

  const { data: teacherData, isLoading } = useQuery(
    ["teachers", teacherSearch],
    () => GetTeacherDataReq({ teacherSearch })
  );

  const handleTeacherSearch = (value: string) => {
    setTeacherSearch(validateAndCleanSearchQuery(value));
  };

  return (
    <div className="p-10">
      <>
        <div className="flex justify-between items-end mb-2">
          <div>
            <CreateTeacher />
          </div>
          <div className="flex items-end md:flex-row sm:flex-col-reverse xsm:flex-col-reverse gap-2">
            <TextField
              size="small"
              id="search_teacher_name"
              placeholder="البحث"
              onChange={(e) => handleTeacherSearch(e.target.value)}
              InputLabelProps={{
                style: {
                  textAlign: "right",
                },
              }}
              inputProps={{
                style: {
                  textAlign: "right",
                  direction: "rtl",
                },
              }}
              style={{
                direction: "rtl",
                textAlign: "right",
              }}
            />
            <div className="font-mainfont text-3xl">المدرسين</div>
          </div>
        </div>
        <div className="border-b-2 border-mainLine pt-2"></div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={100} speed={2} color="black" />
          </div>
        ) : (
          <TeacherTable teacherData={teacherData} />
        )}
      </>
    </div>
  );
};
