import { axios } from "../../../lib/axios";
// import { GroupsType } from "../types";

export const GetCurrentTeacher = async ({ GroupId, teacherId }: any) => {
  const response = await axios.get(`/teacher/${teacherId}/course/${GroupId}`);
  // console.log("response", response);

  return response.data;
};

// done types
