import { axios } from "../../../lib/axios";

const requestBody = {
  entity: "teacher",
};

export const undeleteTeacherReq = async ({ id }) => {
  const response = await axios.patch(`/undelete/${id}`, requestBody);
  return response;
};
// done types
