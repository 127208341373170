import { Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetSingleGroup } from "../api/getSingleGroup";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
import { GetGroupStudent } from "../api/getGroupStudent";
import { GetCurrentStudent } from "../api/getStudent";
import { useQuery } from "react-query";
const colorPalette = [
  "#D8BFD8",
  "#FA8B3A",
  "#FED142",
  "#2DD683",
  "#00B3FF",
  "#FF7276",
  "#8E44AD",
  "#3498DB",
];

export const GroupMetaData = () => {
  const { encryptedGrouptId } = useParams();
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;

  const [TotalPaidStudentAmount, setTotalPaidStudentAmount] = useState(0);
  const [studentNumberCount, setStudentNumberCount] = useState(0);
  const [totalRemindPrice, setTotalRemindPrice] = useState<any>(0);
  const [totalShouldPaidAmount, setTotalShouldPaidAdmount] = useState<any>(0);
  const percentagelong = Number(
    (TotalPaidStudentAmount / studentNumberCount) * 100
  );

  const GroupId = decryptionFun(encryptedGrouptId, secreGrouptKey);

  const {
    data: groupStudentMetaData,
    isSuccess: isGroupStudentMetaDataSuccess,
    isLoading: LoadingRange,
  } = useQuery(
    ["groupStudentMetaData", GroupId],
    async () => GetGroupStudent(GroupId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: classMetaDataStats, isLoading: isClassMetaDataStatsLoading } =
    useQuery(
      ["classMetaDataStats", GroupId],
      async () => GetSingleGroup(GroupId),
      {
        refetchOnWindowFocus: false,
      }
    );

  let percentage;
  if (percentagelong != null) {
    // console.log("yes");
    if (isNaN(percentagelong)) {
      percentage = 0;
    } else {
      percentage = LoadingRange ? 0 : parseFloat(percentagelong.toFixed(1));
    }
  } else {
    console.log("no");
    percentage = 100;
  }

  const chartData = {
    series: [percentage],
    options: {
      chart: {
        height: 350,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },
          dataLabels: {
            name: {
              offsetY: 0,
              show: false,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              offsetY: 10,
              color: "#111",
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
    },
  };

  const completePriceCompletePrcentage =
    (Number(totalRemindPrice) / Number(totalShouldPaidAmount)) * 100;

  const remindPersentage = parseFloat(
    completePriceCompletePrcentage.toFixed(1)
  );

  const moneyCompleteData = {
    series: [remindPersentage ? remindPersentage : 0],
    options: {
      chart: {
        height: 350,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },
          dataLabels: {
            name: {
              offsetY: 0,
              show: false,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              offsetY: 10,
              color: "#111",
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
    },
  };

  const handleStudentTotalPrice = async (groupStudentMetaData) => {
    try {
      let TotalPayment = 0;
      let TotalRemind = 0;
      const calculateTotal = async (results: any[]) => {
        let total = 0; // Initialize a variable to accumulate the total
        let studentNumber = 0; // Initialize a variable to count the students

        for (const result of results) {
          const currentResponse = await GetCurrentStudent({
            GroupId: result.courseID,
            StudentId: result.studentID,
          });
          if (Number(currentResponse.totalPayment) < 25000) {
            total += 1; // Increment total when no payment
          }

          TotalPayment += Number(currentResponse.totalFee);
          studentNumber += 1; // Increment studentNumber in each iteration
          TotalRemind += Number(
            currentResponse.allStudentPayment
              ? currentResponse.allStudentPayment
              : 0
          );
        }

        setTotalPaidStudentAmount(total);
        setStudentNumberCount(studentNumber);
        setTotalShouldPaidAdmount(TotalPayment);
        setTotalRemindPrice(TotalRemind);
      };

      calculateTotal(groupStudentMetaData);
    } catch (error) {
      console.error("Error fetching teacher data:", error);
    }
  };

  useEffect(() => {
    if (isGroupStudentMetaDataSuccess) {
      handleStudentTotalPrice(groupStudentMetaData?.array);
    }
  }, [isGroupStudentMetaDataSuccess]);

  return (
    <>
      {isClassMetaDataStatsLoading ? (
        ""
      ) : (
        <div className="md:flex xmd:block md:h-fit xmd:w-full flex-wrap p-3 w-full">
          <div className="md:w-1/2 md:h-80 sm:h-full xmd:w-full xsm:w-full sm:w-full sm:block xsm:block md:flex">
            <div className="w-full md:px-4 h-80 sm:p-4 xsm:p-4">
              <Card className={`bg-white rounded-md h-full w-full`}>
                <CardContent className="flex h-full items-center relative">
                  <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center w-full">
                    <div className="font-mainfont font-bold flex text-center items-center pt-6">
                      نسبة الطلبة الذين لم <br />
                      يدفعوا اي قسط
                    </div>
                    <div className="font-mainfont font-bold">
                      <div>
                        <ReactApexChart
                          options={chartData.options}
                          series={chartData.series}
                          type="radialBar"
                          height={250}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: colorPalette[0],
                    }}
                    className={`absolute right-0 top-0 h-full pr-3 rounded-tr-md rounded-br-md`}
                  ></div>
                </CardContent>
              </Card>
            </div>
            <div className="w-full md:px-4 h-80 sm:p-4 xsm:p-4">
              <Card className={`bg-white h-full rounded-md w-full`}>
                <CardContent className="flex h-full items-center  relative">
                  <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center w-full">
                    <div className="font-mainfont font-bold flex items-center pt-8">
                      نسبة اكتمال المبلغ
                    </div>
                    <div className="font-mainfont font-bold pt-2">
                      <div>
                        <ReactApexChart
                          options={moneyCompleteData.options}
                          series={moneyCompleteData.series}
                          type="radialBar"
                          height={250}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: colorPalette[1],
                    }}
                    className={`absolute right-0 top-0 h-full pr-3 rounded-tr-md rounded-br-md`}
                  ></div>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="flex-col md:w-1/2 md:h-80 xmd:w-full xsm:w-full sm:w-full sm:block xsm:block md:flex">
            <div className="sm:block xsm:block h-full md:flex flex-wrap justify-between w-full">
              <div className="w-full md:h-full sm:h-32 xsm:h-32 sm:w-full md:w-1/3 md:px-4 sm:p-4 xsm:p-4 flex">
                <Card
                  className={`bg-white rounded-md h-full w-full flex flex-col`}
                >
                  <CardContent className="flex items-center h-full relative flex-grow">
                    <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center w-full">
                      <div className="font-mainfont font-bold">تاريخ البدء</div>
                      <div className="font-mainfont font-bold">
                        {classMetaDataStats?.date_start}
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: colorPalette[2],
                      }}
                      className={`absolute right-0 top-0 h-full pr-3 rounded-tr-md rounded-br-md`}
                    ></div>
                  </CardContent>
                </Card>
              </div>
              <div className="w-full md:h-full sm:h-32 xsm:h-32 sm:w-full md:w-1/3 md:px-4 sm:p-4 xsm:p-4 flex">
                <Card
                  className={`bg-white rounded-md h-full w-full flex flex-col`}
                >
                  <CardContent className="flex items-center h-full relative flex-grow">
                    <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center w-full">
                      <div className="font-mainfont font-bold">
                        تاريخ الانتهاء
                      </div>
                      <div className="font-mainfont font-bold">
                        {classMetaDataStats?.date_end}
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: colorPalette[3],
                      }}
                      className={`absolute right-0 top-0 h-full pr-3 rounded-tr-md rounded-br-md`}
                    ></div>
                  </CardContent>
                </Card>
              </div>
              <div className="w-full md:h-full sm:h-32 xsm:h-32 sm:w-full md:w-1/3 md:px-4 sm:p-4 xsm:p-4 flex">
                <Card
                  className={`bg-white rounded-md h-full w-full flex flex-col`}
                >
                  <CardContent className="flex items-center h-full relative flex-grow">
                    <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center w-full">
                      <div className="font-mainfont font-bold">سعر الكورس</div>
                      <div className="font-mainfont font-bold">
                        {formatNumberWithCommas(classMetaDataStats?.fee)}
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: colorPalette[4],
                      }}
                      className={`absolute right-0 top-0 h-full pr-3 rounded-tr-md rounded-br-md`}
                    ></div>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className="sm:block xsm:block h-full md:flex flex-wrap justify-between w-full">
              <div className="w-full md:h-full sm:h-32 xsm:h-32 sm:w-full md:w-1/3 md:px-4 sm:p-4 xsm:p-4 flex">
                <Card
                  className={`bg-white rounded-md h-full w-full flex flex-col`}
                >
                  <CardContent className="flex items-center h-full relative flex-grow">
                    <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center w-full">
                      <div className="font-mainfont font-bold text-center">
                        مبلغ المعهد من
                        <br /> الكورس
                      </div>
                      <div className="font-mainfont font-bold">
                        {formatNumberWithCommas(
                          (totalShouldPaidAmount * 20) / 100
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: colorPalette[5],
                      }}
                      className={`absolute right-0 top-0 h-full pr-3 rounded-tr-md rounded-br-md`}
                    ></div>
                  </CardContent>
                </Card>
              </div>
              <div className="w-full md:h-full sm:h-32 xsm:h-32 sm:w-full md:w-1/3 md:px-4 sm:p-4 xsm:p-4 flex">
                <Card
                  className={`bg-white rounded-md h-full w-full flex flex-col`}
                >
                  <CardContent className="flex items-center h-full relative flex-grow">
                    <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center w-full">
                      <div className="font-mainfont font-bold text-center">
                        المبلغ الكلي <br /> المستلم
                      </div>

                      <div className="font-mainfont font-bold">
                        {formatNumberWithCommas(totalRemindPrice)}
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: colorPalette[6],
                      }}
                      className={`absolute right-0 top-0 h-full pr-3 rounded-tr-md rounded-br-md`}
                    ></div>
                  </CardContent>
                </Card>
              </div>
              <div className="w-full md:h-full sm:h-32 xsm:h-32 sm:w-full md:w-1/3 md:px-4 sm:p-4 xsm:p-4 flex">
                <Card
                  className={`bg-white rounded-md h-full w-full flex flex-col`}
                >
                  <CardContent className="flex items-center h-full relative flex-grow">
                    <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center w-full">
                      <div className="font-mainfont font-bold text-center">
                        المبلغ الكلي <br /> المستحق
                      </div>
                      <div className="font-mainfont font-bold">
                        {formatNumberWithCommas(totalShouldPaidAmount)}
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: colorPalette[7],
                      }}
                      className={`absolute right-0 top-0 h-full pr-3 rounded-tr-md rounded-br-md`}
                    ></div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
