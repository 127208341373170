import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { GetTeacherDataReq } from "../../teachers/api/getTeacherData";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { GetCurrentTeacher } from "../api/getCurrentTeacher";
import { EditGroupReq } from "../api/editGroup";
import { NumericFormatCustom } from "../../../lib/numberFormat";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";

import { useAuth } from "../../../lib/auth";
import { decryptionFun } from "../../../lib/secretUrl";
import { useParams } from "react-router-dom";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

const editGroupSchema = z.object({
  groupName: z.string().nonempty("الرجاء ادخال اسم الكروب"),
  price: z.string().nonempty("الرجاء ادخال سعر الكورس"),
  teacherId: z.number(),
  startDate: z.string().nonempty(),
  endDate: z.string().nonempty(),
});

const EditGroupModal = ({ GroupId, teacherId, handleCloseModal, editOpen }) => {
  const { user } = useAuth();
  const secretSubjectKey = process.env
    .REACT_APP_SUBJECT_ENCRYPTION_KEY as string;
  const { encryptedClassId, encryptedSubjectId } = useParams();
  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const subjectId = decryptionFun(encryptedSubjectId, secretSubjectKey);
  const classId = decryptionFun(encryptedClassId, secretClassKey);
  const queryClient = useQueryClient();

  const { mutateAsync: editGroupMutate } = useMutation(EditGroupReq, {
    onSuccess: () => {
      queryClient.invalidateQueries(["teacher", teacherId]);
      queryClient.invalidateQueries(["group-list", classId, subjectId, user]);
      handleCloseModal();
    },
  });

  const { data: currentTeacherData } = useQuery(
    ["current-teacher", GroupId, teacherId],
    () => GetCurrentTeacher({ GroupId, teacherId }),
    {
      enabled: !!GroupId && !!teacherId,
      refetchOnWindowFocus: false,
    }
  );

  const { data: teacherData } = useQuery(
    ["teacher-data"],
    () => GetTeacherDataReq(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: zodResolver(editGroupSchema),
    defaultValues: {
      groupName: currentTeacherData?.courseName,
      price: currentTeacherData?.courseFee,
      teacherId: currentTeacherData?.teacherId,
      startDate: currentTeacherData?.courseDateStart,
      endDate: currentTeacherData?.courseDateEnd,
    },
  });

  const onEditSubmit = async (data: any) => {
    try {
      const formData = {
        name: data.groupName,
        fee: data.price,
        date_start: dayjs(data.startDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        date_end: dayjs(data.endDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        teacherId: data.teacherId,
      };

      if (GroupId) {
        await editGroupMutate({ GroupId, formData });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  useEffect(() => {
    if (currentTeacherData) {
      setValue("groupName", currentTeacherData.courseName);
      setValue("price", currentTeacherData.courseFee);
      setValue("teacherId", currentTeacherData.teacherId);
      setValue("startDate", currentTeacherData?.courseDateStart);
      setValue("endDate", currentTeacherData?.courseDateEnd);
    }
  }, [currentTeacherData, teacherData, setValue]);

  return (
    <Modal
      open={editOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styleClasses}>
        <div className="flex justify-between items-center font-mainfont pb-6">
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <div className="font-mainfont">تعديل</div>
        </div>
        <form>
          <div className="py-2 flex items-center justify-between">
            <div className="w-9/12 xsm:w-8/12">
              <Controller
                control={control}
                name="groupName"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    id="groupName"
                    placeholder="ادخل اسم الكروب"
                    error={!!errors.groupName}
                    InputLabelProps={{
                      style: { textAlign: "right" },
                    }}
                    inputProps={{
                      style: { textAlign: "right", direction: "rtl" },
                    }}
                    style={{ direction: "rtl", textAlign: "right" }}
                  />
                )}
              />
              {errors.groupName && (
                <div className="text-right text-xs text-red-600 pt-1">
                  {errors.groupName.message?.toString()}
                </div>
              )}
            </div>
            <div className="font-mainfont">: اسم الكروب</div>
          </div>
          <div className="py-2 flex items-center justify-between">
            <div className="w-9/12 xsm:w-8/12">
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="teacherId"
                  render={({ field }) => (
                    <Select
                      displayEmpty
                      onChange={(e) => {
                        field.onChange(+e.target.value);
                      }}
                      value={field?.value || ""}
                      required
                      style={{ marginTop: "0px", textAlign: "right" }}
                      inputProps={{
                        style: { textAlign: "right" },
                        "aria-label": "Without label",
                      }}
                    >
                      <MenuItem value="">
                        <em>اختر المدرس</em>
                      </MenuItem>
                      {teacherData?.map((teacher) => (
                        <MenuItem key={teacher?.id} value={+teacher?.id}>
                          {teacher?.user?.fullName}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            <div className="font-mainfont">: اسم المدرس</div>
          </div>
          <div className="py-2 flex items-center justify-between">
            <div className="w-9/12 xsm:w-8/12">
              <Controller
                control={control}
                name="price"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    id="price"
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    error={!!errors.price}
                    placeholder="ادخل سعر الكورس"
                    InputLabelProps={{
                      style: { textAlign: "right" },
                    }}
                    inputProps={{
                      style: { textAlign: "right", direction: "rtl" },
                    }}
                    style={{ direction: "rtl", textAlign: "right" }}
                  />
                )}
              />
              {errors.price && (
                <div className="text-right text-xs text-red-600 pt-1">
                  {errors.price.message.toString()}
                </div>
              )}
            </div>
            <div className="font-mainfont">: سعر الكروب</div>
          </div>
          <div className="py-2 flex items-center justify-between">
            <div className="w-9/12 xsm:w-8/12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <DatePicker
                      // {...field}
                      // format="YYYY-MM-DD"
                      value={dayjs(field.value)}
                      onChange={(date: Dayjs | null) => {
                        return field.onChange(
                          date.add(3, "hour")?.toISOString().slice(0, 10) ||
                            null
                        );
                      }}
                      slotProps={{
                        textField: {
                          error: !!errors.startDate,
                          // helperText: errors.startDate?.message,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors.startDate && (
                <div className="text-right text-xs text-red-600 pt-1">
                  {errors.startDate.message.toString()}
                </div>
              )}
            </div>
            <div className="font-mainfont">: تاريخ البدء</div>
          </div>
          <div className="py-2 flex items-center justify-between">
            <div className="w-9/12 xsm:w-8/12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <DatePicker
                      // format="YYYY-MM-DD"
                      value={dayjs(field.value)}
                      onChange={(date: Dayjs | null) => {
                        return field.onChange(
                          date.add(3, "hour")?.toISOString().slice(0, 10) ||
                            null
                        );
                      }}
                      slotProps={{
                        textField: {
                          error: !!errors.endDate,
                          // helperText: errors.endDate?.message,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors.endDate && (
                <div className="text-right text-xs text-red-600 pt-1">
                  {errors.endDate.message.toString()}
                </div>
              )}
            </div>
            <div className="font-mainfont">: تاريخ الانتهاء</div>
          </div>
          <div className="flex justify-center items-center mt-4">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit(onEditSubmit)}
            >
              <div className="font-mainfont">حفظ</div>
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default EditGroupModal;
