import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EditSingleAdmin } from "../api/editSingleAdmin";
import { DeleteSingleAdmin } from "../api/deleteAdmin";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GetSingleAdmin } from "../api/getSingleAdmin";
import { useAuth } from "../../../lib/auth";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";
const inputSchema = z.object({
  fullName: z.string(),
  userName: z.string(),
});

export const EditAdmin = ({ id }: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const { data: adminData } = useQuery(["single-admin", id], () =>
    GetSingleAdmin(id)
  );

  const handleEditOpen = () => {
    setEditOpen(true);
    setValue("fullName", adminData?.fullName);
    setValue("userName", adminData?.userName);
    clearErrors();
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setValue("fullName", "");
    setValue("userName", "");
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
    clearErrors();
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setValue("adminName", "");
  };

  const { mutateAsync: editAdminMutate } = useMutation(EditSingleAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(["admins", user.schoolId]);
      queryClient.invalidateQueries(["single-admin", id]);
      queryClient.invalidateQueries(["profile-single-admin", id]);
      handleEditClose();
    },
  });

  const { mutateAsync: deleteAdminMutate } = useMutation(DeleteSingleAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(["admins", user.schoolId]);
      queryClient.invalidateQueries(["single-admin", id]);
      queryClient.invalidateQueries(["profile-single-admin", id]);
      handleDeleteClose();
      navigate("/admins");
    },
  });

  const onEditSubmit = async (data: any) => {
    try {
      const validatedData = inputSchema.parse(data);
      await editAdminMutate({ id, validatedData });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const onDeleteSubmit = async (data: any) => {
    try {
      await deleteAdminMutate({ id });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="flex justify-between p-4">
      <div className="p-2">
        <Button onClick={handleDeleteOpen} variant="outlined" color="error">
          <div className="font-mainfont">حذف</div>
        </Button>
      </div>
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styleClasses}>
          <div className="flex justify-between items-center font-mainfont pb-6">
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={deleteOpen ? "long-menu" : undefined}
                aria-expanded={deleteOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleDeleteClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="font-mainfont">حذف ادمن</div>
          </div>
          <div className="flex justify-end pb-6">
            <div>هل انت متاكد من حذف هذا الادمن </div>
          </div>
          <div className="flex justify-end pb-6">
            <div>{adminData?.fullName} : الاسم</div>
          </div>
          <div className="flex justify-end pb-6">
            <div>{adminData?.userName} : username</div>
          </div>
          <div className="flex font-mainfont pt-6">
            <Button
              onClick={onDeleteSubmit}
              variant="contained"
              color="primary"
            >
              <div className="font-mainfont">نعم</div>
            </Button>
          </div>
        </Box>
      </Modal>
      <div className="p-2">
        <Button onClick={handleEditOpen} variant="contained" color="primary">
          <div className="font-mainfont">تعديل</div>
        </Button>
      </div>
      <Modal
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styleClasses}>
          <div className="flex justify-between items-center font-mainfont pb-6">
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={editOpen ? "long-menu" : undefined}
                aria-expanded={editOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleEditClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="font-mainfont">تعديل ادمن</div>
          </div>
          <form onSubmit={handleSubmit(onEditSubmit)}>
            <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12">
                <TextField
                  fullWidth
                  id="fullName"
                  placeholder="ادخل اسم الادمن"
                  {...register("fullName", { required: true })}
                  error={!!errors.fullName}
                  helperText={
                    errors.fullName ? (
                      <div className="text-right">الرجاء ادخال اسم الادمن</div>
                    ) : (
                      ""
                    )
                  }
                  InputLabelProps={{
                    style: {
                      textAlign: "right", // Align the label to the right
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: "right", // Align the input text to the right
                      direction: "rtl", // Set text direction for the input
                    },
                  }}
                  style={{
                    direction: "rtl", // Set text direction for the entire TextField
                    textAlign: "right", // Align the entire TextField to the right
                  }}
                />
              </div>
              <div className="font-mainfont">: اسم الادمن</div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="w-9/12 xsm:w-8/12">
                <TextField
                  fullWidth
                  id="userName"
                  placeholder="ادخل اسم المستخدم الادمن"
                  {...register("userName", { required: true })}
                  error={!!errors.userName}
                  helperText={
                    errors.userName ? (
                      <div className="text-right">
                        الرجاء ادخال اسم المستخدم
                      </div>
                    ) : (
                      ""
                    )
                  }
                  InputLabelProps={{
                    style: {
                      textAlign: "right", // Align the label to the right
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: "right", // Align the input text to the right
                      direction: "rtl", // Set text direction for the input
                    },
                  }}
                  style={{
                    direction: "rtl", // Set text direction for the entire TextField
                    textAlign: "right", // Align the entire TextField to the right
                  }}
                />
              </div>
              <div className="font-mainfont">: اسم المستخدم</div>
            </div>
            <div className="flex font-mainfont py-6">
              <Button type="submit" variant="contained" color="primary">
                <div className="font-mainfont">تعديل</div>
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

// done types
