import { Button, TextField, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { LoginCredentialsDTO, loginWithEmailAndPassword } from "../api/login";
import { useAuth } from "../../../lib/auth";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const LoginForm = () => {
  const { login } = useAuth();
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<LoginCredentialsDTO>();
  const navigate = useNavigate();
  const fetch = async (data: LoginCredentialsDTO) => {
    // const response = await loginWithEmailAndPassword(data);
    try {
      await login(data);
      window.location.reload();
    } catch (error) {
      setError(true);
      // console.log(error);
    }
    // console.log(response);

    // navigate("/dashboard");
  };
  const onSubmit = (data: LoginCredentialsDTO) => {
    // console.log("Form data:", data);
    fetch(data);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <>
      {error ? (
        <div className="text-red-600">
          عذرًا، اسم المستخدم أو كلمة المرور غير صحيحة.
        </div>
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-2 flex items-center justify-between">
          <div className="w-9/12 xsm:w-8/12">
            <TextField
              fullWidth
              id="userName"
              placeholder="ادخل اسم المستخدم"
              {...register("userName", { required: true })}
              error={!!errors.userName}
              helperText={
                errors.userName ? (
                  <div className="text-right">الرجاء اسم المستخدم</div>
                ) : (
                  ""
                )
              }
              InputLabelProps={{
                style: {
                  textAlign: "right", // Align the label to the right
                },
              }}
              inputProps={{
                style: {
                  textAlign: "right", // Align the input text to the right
                  direction: "rtl", // Set text direction for the input
                },
              }}
              style={{
                direction: "rtl", // Set text direction for the entire TextField
                textAlign: "right", // Align the entire TextField to the right
              }}
            />
          </div>
          <div className="font-mainfont">: اسم المستخدم</div>
        </div>
        <div className="py-2 flex items-center justify-between">
          <div className="w-9/12 xsm:w-8/12">
            {/* <TextField
              fullWidth
              id="password"
              // type="password"
              placeholder="ادخل كلمة المرور"
              {...register("password", { required: true })}
              error={!!errors.password}
              helperText={
                errors.password ? (
                  <div className="text-right">الرجاء ادخال كلمة المرور</div>
                ) : (
                  ""
                )
              }
              InputLabelProps={{
                style: {
                  textAlign: "right", // Align the label to the right
                },
              }}
              inputProps={{
                style: {
                  textAlign: "right", // Align the input text to the right
                  direction: "rtl", // Set text direction for the input
                },
              }}
              style={{
                direction: "rtl", // Set text direction for the entire TextField
                textAlign: "right", // Align the entire TextField to the right
              }}
            /> */}
            <OutlinedInput
              fullWidth
              placeholder="ادخل الرمز السري"
              id="password"
              type={showPassword ? "text" : "password"}
              {...register("password", { required: true })}
              error={!!errors.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                style: {
                  textAlign: "right", // Align the input text to the right
                  direction: "rtl", // Set text direction for the input
                },
              }}
              style={{
                direction: "rtl", // Set text direction for the entire TextField
                textAlign: "right", // Align the entire TextField to the right
              }}
            />
          </div>
          <div className="font-mainfont">: كلمة المرور</div>
        </div>
        <div className="flex font-mainfont py-6">
          <Button type="submit" variant="contained" color="primary" fullWidth>
            <div className="font-mainfont">تسجيل الدخول</div>
          </Button>
        </div>
      </form>
    </>
  );
};

// done types
