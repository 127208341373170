import { Route, Routes } from "react-router-dom";
import { SingleSujectRoutes } from "../../subject/routes";
import { SingleClassWrapper } from "./singleClassWrapper";
import { useEffect } from "react";

export const SingleClassRoutes = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Routes>
      <Route index element={<SingleClassWrapper />} />
      <Route path=":encryptedSubjectId/*" element={<SingleSujectRoutes />} />
    </Routes>
  );
};
