import { DeletedClassList } from "../components/deletedClassList";
import { DeletedGroupList } from "../components/deletedGroupList";
import { DeletedStudentList } from "../components/deletedStudentList";
import { DeletedSubjectList } from "../components/deletedSubjectList";
import { DeletedTeacherList } from "../components/deletedTeacherList";

export const ArchiveWrapper = () => {
  return (
    <div>
      <>
        <div className="p-10">
          <div className="py-4">
            <DeletedClassList />
          </div>
          <div className="py-4">
            <DeletedSubjectList />
          </div>
          <div className="py-4">
            <DeletedGroupList />
          </div>
          <div className="py-4">
            <DeletedTeacherList />
          </div>
          <div className="py-4">
            <DeletedStudentList />
          </div>
        </div>
      </>
    </div>
  );
};
