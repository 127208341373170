import { axios } from "../../../lib/axios";
import { GroupsType } from "../types";

export const GetSubject = async (classId: any, subjectId: any, user: any) => {
  if (user.role == "teacher") {
    const response2 = await axios.get("/stages");
    let subjectCourse = null;
    console.log("response2", response2);

    response2.data.forEach((result: any) => {
      if (result.id === classId) {
        result.subjects.forEach((singleSubject: any) => {
          if (singleSubject.id === subjectId) {
            subjectCourse = singleSubject.course;
          }
        });
      }
    });

    return { course: subjectCourse }; // Return an object with 'course' property
  } else {
    const response = await axios.get(
      `/subjects/${subjectId}?relations[]=course`
    );
    return response.data;
  }
};
