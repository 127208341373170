// import axios from "axios"; // Import Axios from the external library

// import axios from "axios";

// import { AuthUser } from "../types";
// import storage from "../../../utils/storage";
import { axios } from "../../../lib/axios";

// export const getUser = async () => {
//   // const token = storage.getToken();
//   const headers = {
//     Authorization:
//       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwicm9sZSI6IlNVUEVSQURNSU4iLCJpYXQiOjE2OTQwODE0MzZ9.3W16fNusyhvLMII1BfMVuSXissg6hbN0d2iLooJe47Y", // Replace with your actual JWT token
//     Accept: "application/json",
//   };

//   const config = {
//     headers,
//   };

//   try {
//     const response = await axios.get(`${API_URL}auth/me`, config); // Make a GET request to "/auth/me" with the headers
//     console.log(response.data);
//     // return { data: role };
//     return { data: response.data };
//   } catch (error) {
//     // Handle errors here, e.g., by logging or throwing an exception
//     throw error;
//   }
// };

export const getUser = async () => {
  const response = await axios.get("/auth/me");
  // console.log(response);
  return response;
};

// done with types
