import { useState } from "react";
import { StudentTable } from "../components/studentTable";
import { TextField } from "@mui/material";
import { GetStudentDataReq } from "../api/getStudentData";
import { CreateStudent } from "../components/createStudent";
import { Waveform } from "@uiball/loaders";
import { validateAndCleanSearchQuery } from "../../../utils/validateAndCleanSearchQuery";
import { useQuery } from "react-query";

export const StudentWrapper = () => {
  const [studentSearch, setStudentSearch] = useState("");

  const { data: studentData, isLoading } = useQuery(
    [`students`, studentSearch],
    () => GetStudentDataReq({ studentSearch })
  );

  const handleStudentSearch = (value: string) => {
    setStudentSearch(validateAndCleanSearchQuery(value));
  };

  return (
    <div className="p-10">
      <>
        <div className="flex justify-between items-end mb-2">
          <div>
            <CreateStudent />
          </div>
          <div className="flex items-end md:flex-row sm:flex-col-reverse xsm:flex-col-reverse gap-2">
            <TextField
              size="small"
              id="search_student_name"
              placeholder="البحث"
              onChange={(e) => handleStudentSearch(e.target.value)}
              InputLabelProps={{
                style: {
                  textAlign: "right",
                },
              }}
              inputProps={{
                style: {
                  textAlign: "right",
                  direction: "rtl",
                },
              }}
              style={{
                direction: "rtl",
                textAlign: "right",
              }}
            />
            <div className="font-mainfont text-3xl">الطلاب</div>
          </div>
        </div>
        <div className="border-b-2 border-mainLine pt-2"></div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={100} speed={2} color="black" />
          </div>
        ) : (
          <StudentTable studentData={studentData} />
        )}
      </>
    </div>
  );
};
