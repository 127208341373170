// import React from "react";
import { Route, Routes } from "react-router-dom";
import { TeacherWrapper } from "./TeacherWrapper";
import { TeacherProfileWrapper } from "./teacherProfileWrapper";

export const TeacherRouters = () => {
  return (
    <Routes>
      <Route index element={<TeacherWrapper />} />
      <Route path=":encryptedTeacherId" element={<TeacherProfileWrapper />} />
    </Routes>
  );
};
