import { Waveform } from "@uiball/loaders";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { EditClass } from "./EditClass";
import { encryptionFun } from "../../../lib/secretUrl";
import { ROLES, useAuthorization } from "../../../lib/authorization";

export const ClassList = ({ isLoading, classData }: any) => {
  const secretKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const { checkAccess } = useAuthorization();
  const navigate = useNavigate();

  const HandleNavigate = (id: any) => {
    const enctyptedId = encryptionFun(id, secretKey);
    navigate(`/dashboard/${enctyptedId}`);
  };

  return (
    <>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={100} speed={2} color="black" />
          </div>
        ) : (
          <div>
            <div className="flex flex-wrap justify-end pt-2">
              {classData && classData?.array.length > 0 ? (
                classData?.array?.map(
                  (
                    classItem: { id: number; stage_name: string },
                    index: number
                  ) => (
                    <div
                      className="w-full sm:w-1/2 md:w-1/3 lg:w-2/6 p-2"
                      key={index}
                    >
                      <Card className="bg-white rounded-md h-fit">
                        <div className="flex flex-col justify-between h-fit">
                          <div
                            className="cursor-pointer"
                            onClick={() => HandleNavigate(classItem?.id)}
                          >
                            <div className="mb-1 text-end font-bold p-3 pr-6 text-xl">
                              {classItem?.stage_name}
                            </div>
                            <div className="border-b-2 border-cardLine w-full p-3"></div>
                          </div>
                          {checkAccess({
                            allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
                          }) ? (
                            <EditClass id={classItem?.id} />
                          ) : (
                            ""
                          )}
                        </div>
                      </Card>
                    </div>
                  )
                )
              ) : (
                <div className="flex justify-center p-5">
                  <div>لا يوجد صفوف</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

// done types
