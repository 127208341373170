import { axios } from "../../../lib/axios";

export const EditTeacherReq = async ({ id, data }: any) => {
  // console.log(id, data);

  const response = await axios.patch(`/teacher/${id}`, data);
  // console.log(response);

  return response;
};
// {
//   "teacherName": "string",
//   "phone": "stringstri",
//   "address": "string",
//   "schoolId": 0,
//   "coursesIds": [
//     0
//   ],
//   "speciality": "string",
//   "teacherPaymentIds": [
//     0
//   ],
//   "userName": "string",
//   "password": "string"
// }
