import { axios } from "../../../lib/axios";

const requestBody = {
  entity: "student",
};

export const undeleteStudentReq = async ({ id }: any) => {
  const response = await axios.patch(`/undelete/${id}`, requestBody);
  return response;
};
