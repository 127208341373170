import { useForm } from "react-hook-form";
import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { z } from "zod";
import CloseIcon from "@mui/icons-material/Close";
import { EditClassReq } from "../api/editClass";
import { ROLES, useAuthorization } from "../../../lib/authorization";

import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import { deleteClass } from "../api/deleteClass";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GetsingleClassData } from "../../singleClass/api/getSingleClassData";

const inputSchema = z.object({
  stage_name: z.string(),
});

const Context = React.createContext({ name: "Default" });

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const EditClass = ({ id }: any) => {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { checkAccess } = useAuthorization();

  const { data: classItem } = useQuery(
    ["classData", id],
    () => GetsingleClassData(id),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      stage_name: classItem?.stage_name,
    },
  });

  useEffect(() => {
    setValue("stage_name", classItem?.stage_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classItem?.stage_name, classItem?.id]);

  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(EditClassReq, {
    onSuccess: () => {
      openNotification("topLeft");
      queryClient.invalidateQueries(["classData"]);
    },
  });
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: `تم تعديل الصف بنجاح`,
      key: "edit-success-notification", // Add a unique key here
      placement,
    });
  };

  const [deleteApi, DeleteContextHolder] = notification.useNotification();
  const openDeleteNotification = (placement: NotificationPlacement) => {
    deleteApi.success({
      message: `تم حذف الصف بنجاح`,
      key: "delete-success-notification", // Add a unique key here
      placement,
    });
  };

  const handleEditClick = () => {
    setEditOpen(true);
    clearErrors();
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleDeleteClick = () => {
    setDeleteOpen(true);
    clearErrors();
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const { mutateAsync: deleteClassMutate } = useMutation(deleteClass, {
    onSuccess: () => {
      openDeleteNotification("topLeft");
      queryClient.invalidateQueries(["classData"]);
      setDeleteOpen(false);
    },
  });

  const onDeleteSubmit = async () => {
    deleteClassMutate({ id: classItem?.id });
  };

  const onSubmit = async (data: any) => {
    try {
      const validatedData = inputSchema.parse(data);

      mutateAsync({ id: classItem?.id, stage_name: validatedData.stage_name });
      handleEditClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        {DeleteContextHolder}
        <>
          {checkAccess({
            allowedRoles: [ROLES.SUPERADMIN],
          }) ? (
            classItem?.subjects?.length !== 0 ? (
              <div className="p-4 pr-6">
                <div className="flex justify-end">
                  <Button
                    variant="contained"
                    onClick={handleEditClick}
                    color="primary"
                  >
                    <div className="font-mainfont">تعديل</div>
                  </Button>
                </div>
              </div>
            ) : (
              <div className="p-4 pr-6">
                <div className="flex justify-between">
                  <Button
                    onClick={handleDeleteClick}
                    variant="outlined"
                    color="error"
                  >
                    <div className="font-mainfont">حذف</div>
                  </Button>
                  <Button
                    onClick={handleEditClick}
                    variant="contained"
                    color="primary"
                  >
                    <div className="font-mainfont">تعديل</div>
                  </Button>
                </div>
              </div>
            )
          ) : (
            <div className="p-4 pr-6">
              <div className="flex justify-end">
                <Button
                  onClick={handleEditClick}
                  variant="contained"
                  color="primary"
                >
                  <div className="font-mainfont">تعديل</div>
                </Button>
              </div>
            </div>
          )}
        </>
        <Modal
          open={deleteOpen}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-6">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={deleteOpen ? "long-menu" : undefined}
                  aria-expanded={deleteOpen ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleDeleteClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">حذف الصف</div>
            </div>
            <div className="flex justify-end pb-6">
              <div>هل انت متاكد من حذف</div>
            </div>

            <div className="flex font-mainfont pt-6">
              <Button
                onClick={onDeleteSubmit}
                variant="contained"
                color="primary"
              >
                <div className="font-mainfont">نعم</div>
              </Button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={editOpen}
          onClose={handleEditClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-6">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={editOpen ? "long-menu" : undefined}
                  aria-expanded={editOpen ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleEditClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">تعديل معلومات الصف</div>
            </div>
            <div className="flex justify-end pb-6">
              <div>اسم الصف الحالي : {classItem?.["title"]}</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="py-2 flex items-center justify-between">
                <div className="w-9/12 xsm:w-8/12">
                  <TextField
                    fullWidth
                    id="stage_name"
                    placeholder="ادخل اسم الصف"
                    {...register("stage_name", { required: true })}
                    error={!!errors.stage_name}
                    helperText={
                      errors.stage_name ? (
                        <div className="text-right">
                          الرجاء ادخال الاسم بشكل صحيح
                        </div>
                      ) : (
                        ""
                      )
                    }
                    InputLabelProps={{
                      style: {
                        textAlign: "right", // Align the label to the right
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right", // Align the input text to the right
                        direction: "rtl", // Set text direction for the input
                      },
                    }}
                    style={{
                      direction: "rtl", // Set text direction for the entire TextField
                      textAlign: "right", // Align the entire TextField to the right
                    }}
                  />
                </div>
                <div className="font-mainfont"> : اسم المادة</div>
              </div>
              <div className="flex font-mainfont py-6">
                <Button type="submit" variant="contained" color="primary">
                  <div className="font-mainfont">اضافة</div>
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </Context.Provider>
    </>
  );
};

// done types
