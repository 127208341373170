import { axios } from "../../../lib/axios";

export const GetsingleClassDataReq = async ({ id, user }: any) => {
  if (user.role === "teacher") {

    const response2 = await axios.get(`/stages`);
    if (response2.data.array) {
      const matchingResult = response2.data.array.find(
        (result: any) => result.id === id
      );
      // console.log(matchingResult);

      return matchingResult || null;
    } else {
      const matchingResult = response2.data.find(
        (result: any) => result.id === id
      );
      return matchingResult || null;
    }
  } else {
    const response = await axios.get(`/stages/${id}?relations[]=subjects`);
    // console.log("responseresponseresponseresponseresponse", response);

    return response.data;
  }
  // console.log("response2", response2);

  // If a matching result is found, return it; otherwise, return null
};

// done types
