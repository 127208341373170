import { axios } from "../../../lib/axios";
// import { Student } from "../types";

export const EditTeacherPaymentReq = async ({ id, data }: any) => {
  //   console.log(id, data);

  const response = await axios.patch(`/teacher-payment/${id}`, data);

  return response;
};
