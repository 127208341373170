import { useQuery } from "react-query";
import { useState } from "react";
import { GetCurrentStudent } from "../api/getStudent";

const useStudentTotalPrice = (groupStudentData) => {
  const [totalTeacher, setTotalTeacher] = useState(0);
  const [totalCouldPayToTeacher, setTotalCouldPayToTeacher] = useState(0);
  const [refetchFlag, setRefetchFlag] = useState(0); // Flag for refetching

  const calculateTotal = async (results: any[]) => {
    let total = 0;
    let totalCouldPayToTeacher = 0;

    for (const result of results) {
      const currentResponse = await GetCurrentStudent({
        GroupId: result.courseID,
        StudentId: result.studentID,
      });

      totalCouldPayToTeacher +=
        (Number(currentResponse.totalPayment) * 80) / 100;
      if (currentResponse.totalFee !== 0) {
        total += (Number(currentResponse.totalFee) * 80) / 100;
      }
    }

    setTotalTeacher(total);
    setTotalCouldPayToTeacher(totalCouldPayToTeacher);
  };

  const query = useQuery(
    ["studentTotalPrice", groupStudentData, refetchFlag],
    () => calculateTotal(groupStudentData?.array || []),
    {
      enabled: !!groupStudentData?.array,
      onSuccess: () => {
        // Set the loading state to false
      },
      onError: (error) => {
        console.error("Error fetching teacher data:", error);
      },
    }
  );

  const refetchData = () => {
    setRefetchFlag((prev) => prev + 1); // Toggle the refetch flag
    query.refetch(); // Call the refetch method from React Query
  };

  return {
    totalTeacher,
    totalCouldPayToTeacher,
    isLoading: query.isLoading,
    isError: query.isError,
    refetchData,
  };
};

export default useStudentTotalPrice;
