import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProfileWrapper } from "./profileWrapper";

export const ProfileRouters = () => {
  return (
    <Routes>
      <Route index element={<ProfileWrapper />} />
    </Routes>
  );
};
