import { Breadcrumbs } from "@mui/material";
import { GetsingleClassData } from "../api/getSingleClassData";
import { useNavigate, useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { Waveform } from "@uiball/loaders";
import { MetaDataCard } from "../../../components/elements/metaDataCard";
import { EditSingleClass } from "./editSingleClass";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { useQuery } from "react-query";

export const SingleClass = () => {
  const secretKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const navigate = useNavigate();
  const { checkAccess } = useAuthorization();
  const { encryptedClassId } = useParams();

  const id = decryptionFun(encryptedClassId, secretKey);

  const { data: singleClassMetaDataState, isLoading } = useQuery(
    ["singleClassMetaData", id],
    () => GetsingleClassData(id),
    {
      enabled: !!id,
    }
  );

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center p-16">
          <Waveform size={40} speed={2} color="black" />
        </div>
      ) : (
        <div>
          <div className="pl-5 flex justify-between">
            <Breadcrumbs aria-label="breadcrumb">
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() => navigate(`/dashboard`)}
              >
                الصفوف
              </div>
              <div className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer">
                {singleClassMetaDataState?.stage_name}
              </div>
            </Breadcrumbs>
          </div>
          {checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN] }) && (
            <>
              <EditSingleClass />
              <div className="flex flex-wrap justify-end px-5">
                <MetaDataCard
                  index={0}
                  title="عدد المواد"
                  data={singleClassMetaDataState?.subjects.length}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

// done types
