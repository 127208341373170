// import { TextField } from "@mui/material";
import { Button, TextField } from "@mui/material";
import * as React from "react";

// import logo from "@/assets/logo.svg";
// import { Link } from "@/components/Elements";
// import { Head } from "@/components/Head";
type LayoutProps = {
  children: React.ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <div className="h-screen bg-[#F6F6F6] flex flex-col justify-center">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="bg-white py-6 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="text-center mb-4">
              <div className="text-2xl font-bold mb-4">تسجيل الدخول</div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// done with types
