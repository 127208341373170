import { axios } from "../../../lib/axios";
// import { GroupsType } from "../types";

export const GetSingleGroup = async (id: any) => {
  // console.log("id", id);

  const response = await axios.get(`/courses/${id}`);
  return response.data;
};

// done types
