import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import { deletedTeacherReq } from "../api/deletedTeacherReq";
import { Fragment, Key } from "react";
import { Waveform } from "@uiball/loaders";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { UndeletedTeacher } from "./undeletedTeacher";
import { useQuery } from "react-query";

function Row(props: { row: any }) {
  const { row } = props;

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">
          <UndeletedTeacher row={row} />
        </TableCell>
        <TableCell align="center">{row.teacher.specialty}</TableCell>
        <TableCell align="center">{row.teacher.address}</TableCell>
        <TableCell align="center">{row.teacher.phone}</TableCell>
        <TableCell align="center">
          <div className="flex justify-center items-center">
            {row.user.fullName}
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export const DeletedTeacherList = () => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const { data: teacherList, isLoading } = useQuery(
    ["deleted-teacher-list"],
    () => deletedTeacherReq()
  );

  const handleChange = () => {
    setChecked((prev) => !prev);
    setOpen(!open);
  };

  return (
    <>
      <div className="flex justify-end items-center mb-2">
        <div className="flex cursor-pointer" onClick={handleChange}>
          <div className="font-mainfont text-2xl">المدرسين</div>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
      </div>
      <div className="border-b-2 border-mainLine pt-3"></div>
      <Collapse in={checked}>
        <div className="pt-5">
          {isLoading ? (
            <div className="flex justify-center items-center p-16">
              <Waveform size={40} speed={2} color="black" />
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell /> */}
                    <TableCell align="center">
                      <div className="font-mainfont text-base"></div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="font-mainfont text-base">الاختصاص</div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="font-mainfont text-base">عنوان السكن</div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="font-mainfont text-base">رقم الهاتف</div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="font-mainfont text-base">اسم المدرس</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teacherList.length > 0
                    ? teacherList?.map(
                        (row: any, index: Key | null | undefined) => (
                          <Row key={index} row={row} />
                        )
                      )
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {isLoading ? (
            ""
          ) : teacherList.length > 0 ? (
            ""
          ) : (
            <div className="flex justify-center p-5">
              <div>لا يوجد مدرسيين</div>
            </div>
          )}
        </div>
      </Collapse>
    </>
  );
};
