import { Route, Routes } from "react-router-dom";
import { ClassWrapper } from "./classWrapper";
import { SingleClassRoutes } from "../../singleClass/router";
export const ClassRouters = () => {
  return (
    <Routes>
      <Route index element={<ClassWrapper />} />
      <Route path=":encryptedClassId/*" element={<SingleClassRoutes />} />
    </Routes>
  );
};
