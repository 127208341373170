import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { GetStudentDataReq } from "../../students/api/getStudentData";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import Autocomplete from "@mui/material/Autocomplete";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import { decryptionFun } from "../../../lib/secretUrl";
import { useParams } from "react-router-dom";
import { AddStudentReq } from "../api/addStudent";
import { NumericFormatCustom } from "../../../lib/numberFormat";
import { GetSingleGroup } from "../api/getSingleGroup";
import { GetCurrentStudent } from "../api/getStudent";
import { CreateStudentPaymentReq } from "../api/createStudentPayment";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
import { useQuery, useQueryClient } from "react-query";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

const Context = React.createContext({ name: "Default" });

export const AddStudent = () => {
  const queryClient = useQueryClient();
  const { encryptedGrouptId } = useParams();
  const { checkAccess } = useAuthorization();
  const [open, setOpen] = useState<boolean>(false);
  const [students, setStudents] = useState("");
  const [isBook, setIsbook] = useState(false);
  const [discount, setDiscount] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const secretGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;

  const openNotification = (placement: NotificationPlacement) => {
    api.error({
      message: `الطالب موجود بالفعل`,
      placement,
    });
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const GroupId = decryptionFun(encryptedGrouptId, secretGrouptKey);

  const { data: studentList } = useQuery(["get-current-student"], () =>
    GetStudentDataReq()
  );

  const { data: thisGroupData } = useQuery(
    ["get-current-student", GroupId],
    () => GetSingleGroup(GroupId),
    {
      enabled: !!GroupId,
    }
  );

  const handleClick = () => {
    setOpen(true);

    setStudents("");
    setDiscount("");
    setIsbook(false);

    clearErrors();
  };
  const handleChange = (event: any) => {
    if (event) {
      setStudents(event.id as string);
    }
  };
  const handleDiscountChange = (event: SelectChangeEvent) => {
    setDiscount(event.target.value as string);
  };

  const handleBookChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsbook(event.target.checked);
  };

  const onSubmit = async (data: any) => {
    try {
      if (encryptedGrouptId) {
        const GroupId = decryptionFun(encryptedGrouptId, secretGrouptKey);
        let finalDiscount;

        if (Number(discount) === 10) {
          finalDiscount = thisGroupData.fee;
        } else if (Number(discount) === 20) {
          finalDiscount =
            thisGroupData.fee - Number(data.payment_amount.replace(/,/g, ""));
        } else {
          finalDiscount = 0;
        }

        const combinedData = {
          studentID: students,
          courseID: GroupId,
          teacherDiscount: Number(finalDiscount),
        };

        const response = await AddStudentReq(combinedData);

        if (response.status === 201) {
          if (isBook === true) {
            const currentTime = new Date().toISOString();
            const singleStudentResponse = await GetCurrentStudent({
              GroupId: GroupId,
              StudentId: students,
            });

            const validDate = {
              amount_paid: Number(25000),
              courseId: GroupId,
              date: currentTime,
              studentId: students,
              studentGroupId: singleStudentResponse.studentGroupId,
              isBook: true,
            };

            const createResponse = await CreateStudentPaymentReq({
              data: validDate,
            });
            if (createResponse.status === 409) {
              openNotification("topLeft");
            }
          }
        }

        queryClient.invalidateQueries(["get-student-group", GroupId]);
        handleClose();
      }
    } catch (error) {
      openNotification("topLeft");
      handleClose();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  const validateAmount = (value: any) => {
    const amount = parseFloat(value.replace(/,/g, ""));

    if (isNaN(amount) || amount < 250) {
      return "المبلغ يجب أن يكون أكبر من 250";
    }
    return true;
  };

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        {checkAccess({
          allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
        }) ? (
          <div className="flex justify-between items-center">
            <div className="flex font-mainfont py-6">
              <Button
                onClick={handleClick}
                type="submit"
                variant="contained"
                color="primary"
              >
                <div className="font-mainfont">اضافة طالب</div>
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={styleClasses}>
                  <div className="flex justify-between items-center font-mainfont pb-6">
                    <div>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className="font-mainfont">اضافة طالب</div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="py-2 flex items-center justify-between">
                      <div className="w-9/12 xsm:w-8/12">
                        <FormControl fullWidth>
                          <Autocomplete
                            id="teacher-autocomplete"
                            onChange={(event, newValue) => {
                              handleChange(newValue);
                            }}
                            options={studentList}
                            getOptionLabel={(student: any) =>
                              student.student_name
                            }
                            style={{
                              marginTop: "0px",
                              textAlign: "left",
                            }}
                            renderOption={(props, student) => (
                              <li {...props}>
                                <div className="flex flex-col justify-end">
                                  <div className="flex justify-end">
                                    <div>{student.student_name}</div>
                                    <div>&nbsp;: الاسم</div>
                                  </div>
                                  <div className="text-sm flex justify-end">
                                    <div>
                                      {new Date(
                                        student.createdAt
                                      ).toLocaleDateString("en-GB", {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                      })}
                                    </div>
                                    <div>&nbsp;: تاريخ الانشاء</div>
                                  </div>
                                </div>
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                dir="rtl"
                                required
                                variant="outlined"
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className="font-mainfont">: اسم الطالب</div>
                    </div>
                    <div className="py-2 flex items-center justify-between">
                      <div className="w-9/12 xsm:w-8/12">
                        <FormControl fullWidth>
                          <Select
                            value={discount}
                            onChange={handleDiscountChange}
                            required
                            displayEmpty
                            name="teacher_name"
                            style={{
                              marginTop: "0px",
                              textAlign: "right",
                            }}
                            inputProps={{
                              style: {
                                textAlign: "right",
                              },
                              "aria-label": "Without label",
                            }}
                          >
                            <MenuItem
                              className="flex flex-col justify-end"
                              value={10}
                            >
                              خصم كامل - خصم 100% من سعر الكورس
                            </MenuItem>
                            <MenuItem
                              className="flex flex-col justify-end"
                              value={20}
                            >
                              خصم جزئي - خصم مبلغ معين من الكورس
                            </MenuItem>
                            <MenuItem
                              className="flex flex-col justify-end"
                              value={30}
                            >
                              بدون خصم
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="font-mainfont">
                        {" "}
                        : الرجاء ادخال نوع الخصم
                      </div>
                    </div>
                    {discount == "20" && (
                      <>
                        <div className="py-2 flex items-center justify-between">
                          <div className="w-9/12 xsm:w-8/12 flex justify-end">
                            <FormControl fullWidth>
                              <TextField
                                fullWidth
                                id="payment_amount"
                                placeholder="ادخل المبلغ"
                                {...register("payment_amount", {
                                  required: true,
                                  validate: validateAmount,
                                })}
                                error={!!errors.payment_amount}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                                style={{
                                  direction: "rtl",
                                  textAlign: "right",
                                }}
                              />
                              {errors.payment_amount && (
                                <div className="text-right text-xs text-red-600 pt-1">
                                  المبلغ يجب أن يكون رقمًا أكبر من 250
                                </div>
                              )}
                            </FormControl>
                          </div>
                          <div className="font-mainfont">
                            : سعر الكورس للطالب
                          </div>
                        </div>
                        <div className="py-2 flex items-center justify-between">
                          <div className="w-9/12 xsm:w-8/12 flex pr-4 justify-end">
                            {/* {thisGroupData.fee} */}
                            {formatNumberWithCommas(
                              thisGroupData.fee ? thisGroupData.fee : 0
                            )}
                          </div>
                          <div className="font-mainfont">
                            : سعر الكورس الاصلي
                          </div>
                        </div>
                      </>
                    )}
                    <div className="py-2 flex items-center justify-end">
                      <div className="">
                        <Checkbox
                          checked={isBook}
                          onChange={handleBookChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                      <div className="font-mainfont">
                        : هل دفع الطالب مبلغ الحجز
                      </div>
                    </div>
                    <div className="flex font-mainfont pt-6">
                      <Button type="submit" variant="contained" color="primary">
                        <div className="font-mainfont">اضافة</div>
                      </Button>
                    </div>
                  </form>
                </Box>
              </Modal>
            </div>{" "}
            <div className="font-mainfont text-3xl">
              {/* {singleClassData?.title} */}الطلاب
            </div>
          </div>
        ) : (
          <div className="flex justify-end p-5 items-center">
            <div className="font-mainfont text-3xl">
              {/* {singleClassData?.title} */}الطلاب
            </div>
          </div>
        )}{" "}
      </Context.Provider>
    </>
  );
};
