import { axios } from "../../../lib/axios";
import { TeacherType } from "../types";

export const GetSingleTeacherCourseDataReq = async (
  id: any
): Promise<TeacherType> => {
  const response = await axios.get(
    `/teacher/${id}?relations[]=course&relations[]=user&relations`
  );
  // console.log("responseresponseresponseresponse", response);

  return response.data;
};
