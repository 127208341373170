import { axios } from "../../../lib/axios";
import { TeacherType } from "../types";

export const GetSingleTeacherDataReq = async (
  id: any
): Promise<TeacherType> => {
  const response = await axios.get(
    `/teacher/${id}?relations[]=course&relations[]=user&relations[]=teacher_payment`
  );

  return response.data;
};
