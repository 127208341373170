import { axios } from "../../../lib/axios";
// import { createTeacherType } from "../types";
export interface SubjectData {
  stageId: number;
  subject_name: string;
}
export const CreateSujbectReq = async ({
  stageId,
  subject_name,
}: SubjectData) => {
  try {
    const response = await axios.post("/subjects", { stageId, subject_name });
    return response;
  } catch (error) {
    // Handle errors here
    throw error;
  }
};
