import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { encryptionFun } from "../../../lib/secretUrl";
import Tooltip from "@mui/material/Tooltip";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
interface ClassCardProps {
  studentGroup: any;
}
export const StudentGroupCard: React.FC<ClassCardProps> = ({
  studentGroup,
}) => {
  const navigate = useNavigate();

  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const secretSubjectKey = process.env
    .REACT_APP_SUBJECT_ENCRYPTION_KEY as string;
  const secreGrouptKey = process.env.REACT_APP_GROUP_ENCRYPTION_KEY as string;
  const handleStageNavigae = (stageId: any) => {
    const enctyptedStageId = encryptionFun(stageId, secretClassKey);
    navigate(`/dashboard/${enctyptedStageId}`);
  };

  const handleSubjectNavigae = (stageId: any, subjectId: any) => {
    const enctyptedStageId = encryptionFun(stageId, secretClassKey);

    const enctyptedSubjectId = encryptionFun(subjectId, secretSubjectKey);
    // console.log(`/dashboard/${enctyptedStageId}/${enctyptedSubjectId}`);

    navigate(`/dashboard/${enctyptedStageId}/${enctyptedSubjectId}`);
  };

  const handleGroupNavigae = (stageId: any, subjectId: any, courseId: any) => {
    const enctyptedStageId = encryptionFun(stageId, secretClassKey);

    const enctyptedSubjectId = encryptionFun(subjectId, secretSubjectKey);

    const enctyptedGrouptId = encryptionFun(courseId, secreGrouptKey);
    // console.log(
    //   `/dashboard/${enctyptedStageId}/${enctyptedSubjectId}/${enctyptedGrouptId}`
    // );

    navigate(
      `/dashboard/${enctyptedStageId}/${enctyptedSubjectId}/${enctyptedGrouptId}`
    );
  };

  // console.log(studentGroup);

  return (
    <Card className="bg-white rounded-md h-full">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div
            className="mb-1 text-end font-bold p-3 pr-6 text-xl cursor-pointer hover:underline"
            onClick={() =>
              handleGroupNavigae(
                studentGroup?.subject?.stage?.id,
                studentGroup?.subject?.id,
                studentGroup.id
              )
            }
          >
            <Tooltip title="الانتقال الى هذا الكروب">
              {studentGroup && <>{studentGroup.name}</>}
            </Tooltip>
          </div>
          <div className="border-b-2 border-cardLine w-full"></div>
          <div className="flex flex-col items-end p-6">
            <div className="flex">
              {studentGroup.date_end}
              <div className="font-semibold pl-1"> : تاريخ الانتهاء</div>
            </div>
            <div className="flex">
              {studentGroup.date_start}
              <div className="font-semibold pl-1"> : تاريخ البدء</div>
            </div>
            <div className="flex">
              {formatNumberWithCommas(Number(studentGroup.fee))}
              <div className="font-semibold pl-1"> : سعر الكورس</div>
            </div>
            {/* <div className="flex">
              {studentGroup.discount_percentage}
              <div className="font-semibold pl-1"> : سعر الكوس للطالب</div>
            </div> */}
            {/* <div className="flex">
              {studentGroup.discount_percentage}
              <div className="font-semibold pl-1"> : مجموع المبلغ المدفوع</div>
            </div> */}

            <div className="flex">
              <div
                className="pr-4 cursor-pointer hover:underline"
                onClick={() =>
                  handleSubjectNavigae(
                    studentGroup?.subject?.stage?.id,
                    studentGroup?.subject.id
                  )
                }
              >
                <Tooltip title="الانتقال الى هذه المادة">
                  {studentGroup && <>{studentGroup?.subject?.subject_name}</>}
                </Tooltip>
              </div>
              <div className="font-semibold pl-1"> : المادة</div>
            </div>
            <div className="flex ">
              <div
                className="pr-4 cursor-pointer hover:underline"
                onClick={() =>
                  handleStageNavigae(studentGroup.subject.stage.id)
                }
              >
                <Tooltip title="الانتقال الى هذا الصف">
                  {studentGroup && (
                    <>{studentGroup?.subject.stage.stage_name}</>
                  )}
                </Tooltip>
              </div>
              <div className="font-semibold pl-1"> : الصف</div>
            </div>
          </div>
          <div>
            <div className="text-end">
              {/* عدد الطلاب الكلي : {totalStudents} طالب */}
            </div>
            <div className="text-end">
              {/* المبلغ الكلي المستحق : {totalDueAmount} */}
            </div>
            <div className="text-end">
              {/* المبلغ الكلي المدفوع : {totalPaidAmount} */}
            </div>
            <div className="text-end">
              {/* المبلغ الكلي المتبقي : {totalPaidAmount} */}
            </div>{" "}
            {/* <div className="flex items-center flex-col">
              <div className="font-semibold">دفعات الطالب</div> <br />
            </div> */}
            <div className="text-end flex flex-col justify-end p-2">
              {/* {studentPayments?.length > 0 ? (
                <>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 150 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">الاجراءات</TableCell>
                          <TableCell align="center">التاريخ</TableCell>
                          <TableCell align="center">المبلغ</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {studentPayments.map((payment: any, index: any) => (
                          <TableRow
                            key={index}
                            sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell align="center" className="flex">
                              <div className="flex">
                                <DeletePayment
                                  payment={payment}
                                  studentPayments={studentPayments}
                                  setStudentPayments={setStudentPayments}
                                />
                                <EditPayment
                                  payment={payment}
                                  studentPayments={studentPayments}
                                  setStudentPayments={setStudentPayments}
                                  updatePayment={updatePayment}
                                />
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <div>{payment.date}</div>
                            </TableCell>
                            <TableCell align="center">
                              <div>
                                {formatNumberWithCommas(
                                  Number(payment.amount_paid)
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <div className="flex justify-center p-4">
                  <div>لايوجد اي دفعات </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="p-2 flex flex-col flex-end justify-end">
          {/* <div className="border-b-2 border-cardLine w-full p-4"></div> */}
          {/* <div className="p-2 flex flex-end justify-between">
            <div className="pt-2">
              <AddStudentDiscount />
            </div>
            <div className="p-2">
              <CreateStudentPayment
                studentId={studentGroup.studentID}
                courseId={studentGroup.course.id}
                studentGroupId={studentGroup.id}
                createPay={createPay}

                fetchData={fetchData}
                courseId={teacherGroupData.id}
                teacherId={teacherGroupData.teacherId}
              />
            </div>
          </div> */}

          {/* <div className="p-2">
          <Button variant="contained" color="primary">
            <div className="font-mainfont">عرض</div>
          </Button>
        </div> */}
        </div>
      </div>
    </Card>
  );
};
