import { Breadcrumbs } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { decryptionFun } from "../../../lib/secretUrl";
import { Waveform } from "@uiball/loaders";
import { GetsingleClassDataReq } from "../api/getSingleClass";
import { GetsingleSubjectData } from "../api/getSingleSubject";
import { EditSingleSubject } from "./editSingleSubject";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { useAuth } from "../../../lib/auth";
import { useQuery } from "react-query";

export const SingleSubject = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { checkAccess } = useAuthorization();
  const { encryptedClassId, encryptedSubjectId } = useParams();

  const secretSubjectKey = process.env
    .REACT_APP_SUBJECT_ENCRYPTION_KEY as string;

  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;

  const subjectId = decryptionFun(encryptedSubjectId, secretSubjectKey);
  const classId = decryptionFun(encryptedClassId, secretClassKey);

  const { data: subjectData, isLoading } = useQuery(
    ["subject-data", subjectId],
    () => GetsingleSubjectData(subjectId)
  );

  const { data: classData } = useQuery(["class-data", classId], () =>
    GetsingleClassDataReq({ id: classId, user })
  );

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center p-16">
          <Waveform size={40} speed={2} color="black" />
        </div>
      ) : (
        <>
          <div className="pl-5 flex justify-between">
            <Breadcrumbs aria-label="breadcrumb">
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() => navigate(`/dashboard`)}
              >
                الصفوف
              </div>
              <div
                className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer"
                onClick={() => navigate(`/dashboard/${encryptedClassId}`)}
              >
                {classData?.stage_name}
              </div>
              <div className="font-mainfont sm:text-sm xsm:text-xs md:text-md hover:underline cursor-pointer">
                {subjectData?.subject_name}
              </div>
            </Breadcrumbs>
          </div>
          <div className="flex flex-col px-5">
            {checkAccess({
              allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
            }) ? (
              <div className="flex justify-between items-center">
                <EditSingleSubject id={subjectId} />
                <div className="font-mainfont text-3xl">
                  {subjectData?.subject_name}
                </div>
              </div>
            ) : (
              <div className="flex justify-end p-5 items-center">
                <div className="font-mainfont text-3xl">
                  {subjectData?.subject_name}
                </div>
              </div>
            )}
            <div className="border-b-2 border-mainLine"></div>
          </div>
          {checkAccess({
            allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
          }) ? (
            <div className="flex flex-wrap justify-end p-3">
              {/* {subjectMetaDataStats.map((card: any, index: number) => (
                <MetaDataCard
                  key={index}
                  index={index}
                  title={card.title}
                  data={card.data}
                />
              ))} */}
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

// done types
