import { Waveform } from "@uiball/loaders";
import { Card } from "@mui/material";
import { EditSubject } from "./EditSubject";
import { useNavigate, useParams } from "react-router-dom";
import { encryptionFun } from "../../../lib/secretUrl";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import { CreateSubject } from "./CreateSubject";
import { decryptionFun } from "../../../lib/secretUrl";
import { GetsingleClassDataReq } from "../../subject/api/getSingleClass";
import { useAuth } from "../../../lib/auth";
import { useQuery } from "react-query";

export const SubjectList = () => {
  const { encryptedClassId } = useParams();
  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;
  const secretKey = process.env.REACT_APP_SUBJECT_ENCRYPTION_KEY as string;
  const navigate = useNavigate();
  const { checkAccess } = useAuthorization();
  const { user } = useAuth();

  const id = decryptionFun(encryptedClassId, secretClassKey);

  const { data, isLoading } = useQuery(["subjects", id, user], () =>
    GetsingleClassDataReq({ id, user })
  );

  const handleClass = (id: number) => {
    const enctyptedSubjectId = encryptionFun(id, secretKey);
    navigate(`/dashboard/${encryptedClassId}/${enctyptedSubjectId}`);
  };

  return (
    <>
      <CreateSubject />
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={40} speed={2} color="black" />
          </div>
        ) : (
          <div>
            {data?.subjects?.length === 0 ? (
              <div className="flex justify-center p-5">
                <div>لا يوجد مواد</div>
              </div>
            ) : (
              <div className="flex flex-wrap justify-end pt-2">
                {data?.subjects?.map((data, index: number) => (
                  <div
                    key={index}
                    className="w-full sm:w-1/2 md:w-1/3 lg:w-2/6 p-2 h-fit"
                  >
                    <Card className="bg-white rounded-md h-fit">
                      <div className="flex flex-col h-fit justify-between">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleClass(data.id)}
                        >
                          <div className="mb-1 text-end font-bold p-3 pr-6 text-xl">
                            {data.subject_name}
                          </div>
                        </div>
                        {checkAccess({
                          allowedRoles: [ROLES.ADMIN, ROLES.SUPERADMIN],
                        }) ? (
                          <div>
                            <div className="border-b-2 border-cardLine w-full p-3"></div>
                            <EditSubject id={data.id} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
