import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { encryptionFun } from "../../../lib/secretUrl";
import { EditAdmin } from "./editAdmin";
import { AdminDataProp } from "../types";

export const AdminCard: React.FC<AdminDataProp> = ({ id, name, username }) => {
  const secretKey = process.env.REACT_APP_ADMIN_ENCRYPTION_KEY as string;
  const navigate = useNavigate();

  const HandleNavigate = () => {
    const encryptedId = encryptionFun(id, secretKey);
    navigate(`/admins/${encryptedId}`);
  };

  return (
    <Card className="bg-white rounded-md h-min">
      <div
        className="flex flex-col cursor-pointer"
        onClick={() => HandleNavigate()}
      >
        <div className="text-end font-bold pr-6 text-xl flex itesm-center justify-end">
          <div className="p-4">{name}</div>
        </div>
        <div className="border-b-2 border-cardLine w-full"></div>
        <div className="text-end text-xl flex itesm-center pr-6 justify-end">
          <div className="p-3">{username}</div>
          <div className="p-3 font-bold font-mainfont"> : username</div>
        </div>
        <div className="border-b-2 border-cardLine w-full"></div>
      </div>
      <EditAdmin id={id} />
    </Card>
  );
};
