import { axios } from "../../../lib/axios";
import { CardMetaData } from "../types";

export const getClassMetaData = async (): Promise<CardMetaData> => {
  const response = await axios.get(`/courses/stats`);
  // console.log(response.data.array);
  return response.data;
};

// done types
