import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { NumericFormatCustom } from "../../../lib/numberFormat";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const CreateStudentPayment = ({
  studentGroup,
  createPay,
  didPayFee,
}: any) => {
  const [open, setOpen] = useState(false);
  const [isBook, setIsbook] = useState(false);
  const [receiptDate, setReceiptDate] = useState<Dayjs | null>(null);
  const [receiptDateError, setReceiptDateError] = useState<boolean>(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const [itsBigger, setItsBegger] = useState(false);
  const fetchEditData = async () => {
    try {
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const validateAmount = (value: any) => {
    const amount = parseFloat(value.replace(/,/g, ""));
    // console.log("amount", amount);

    if (isNaN(amount) || amount < 250) {
      return "المبلغ يجب أن يكون أكبر من 250";
    }
    return true;
  };
  const handleClick = () => {
    setOpen(true);
    // console.log(studentGroupId);
    setItsBegger(false);
    setIsbook(false);
    setValue("payment_amount", "");
    setValue("receptName", "");
    clearErrors();
    setReceiptDate(null);
    setReceiptDateError(false);
    fetchEditData();
  };

  const handleClose = () => {
    setOpen(false);
    setItsBegger(false);
  };

  const onSubmit = async (data: any) => {
    try {
      const currentTime = new Date().toISOString();
      if (!receiptDate) {
        setReceiptDateError(true);
        return; // Exit the function early if no date is selected
      } else {
        setReceiptDateError(false); // Reset the error if a date is selected
      }

      const formattedReceiptDate = receiptDate.format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );

      if (isBook) {
        const validDate = {
          amount_paid: Number(25000),
          date: currentTime,
          studentGroupId: studentGroup.studentGroupId,
          isBook: true,
          invoiceName: data.receptName,
          invoiceDate: formattedReceiptDate,
        };

        await createPay({ validDate, setItsBegger });
        handleClose();
      } else {
        const validDate = {
          amount_paid: +data.payment_amount.replace(/,/g, ""),
          date: currentTime,
          studentGroupId: studentGroup.studentGroupId,
          isBook: false,
          invoiceName: data.receptName,
          invoiceDate: formattedReceiptDate,
        };

        await createPay({ validDate, setItsBegger });
        handleClose();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleBookChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsbook(event.target.checked);
  };
  const handleDateChange = (newValue: any) => {
    if (newValue && newValue.isValid && newValue.isValid()) {
      // Set the receiptDate directly as the newValue (a Dayjs object)
      setReceiptDate(newValue);

      // Reset the error state
      setReceiptDateError(false);
    } else {
      setReceiptDateError(true);
    }
  };

  // console.log("didPayFee", didPayFee);
  const validateString = (value: any) => {
    // Check if the value is a string
    if (typeof value !== "string") {
      return "يجب أن يكون الإدخال نصًا";
    }
    return true;
  };
  return (
    <>
      <Button
        onClick={() => handleClick()}
        variant="contained"
        color="secondary"
      >
        <div className="font-mainfont">اضافة دفعة</div>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styleClasses}>
          <div className="flex justify-between items-center font-mainfont pb-6">
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="font-mainfont">اضافة دفعة</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isBook ? (
              ""
            ) : (
              <>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <TextField
                      fullWidth
                      id="receptName"
                      placeholder="ادخل الاسم"
                      {...register("receptName", {
                        required: true,
                        validate: validateString, // Add the custom validation function
                      })}
                      error={!!errors.receptName}
                      style={{
                        direction: "rtl", // Set text direction for the entire TextField
                        textAlign: "right", // Align the entire TextField to the right
                      }}
                    />
                    {errors.receptName && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال اسم الوصل
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont md:text-base xsm:text-xs sm:text-xs">
                    : اسم الوصل
                  </div>
                </div>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <TextField
                      fullWidth
                      id="payment_amount"
                      // type="number"
                      placeholder="ادخل المبلغ"
                      {...register("payment_amount", {
                        required: true,
                        validate: validateAmount, // Add the custom validation function
                      })}
                      error={!!errors.payment_amount || !!itsBigger}
                      InputProps={{
                        inputComponent: NumericFormatCustom as any,
                      }}
                      style={{
                        direction: "rtl", // Set text direction for the entire TextField
                        textAlign: "right", // Align the entire TextField to the right
                      }}
                    />
                    {errors.payment_amount && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        المبلغ يجب أن يكون رقمًا أكبر من 250
                      </div>
                    )}
                    {itsBigger && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        عذرا المبلغ المدخل اعلى من سعر الكورس المتبقي
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont"> : الرجاء ادخال المبلغ</div>
                </div>
                <div className="py-2 flex items-center justify-between">
                  <div className="w-9/12 xsm:w-8/12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          className="w-full"
                          value={receiptDate}
                          onChange={(newDate) => handleDateChange(newDate)}
                          format="DD-MM-YYYY"
                          minDate={dayjs("2000-01-01")}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {receiptDateError === true && (
                      <div className="text-right text-xs text-red-600 pt-1">
                        الرجاء ادخال تاريخ الوصل
                      </div>
                    )}
                  </div>
                  <div className="font-mainfont">: تاريخ الوصل</div>
                </div>
              </>
            )}
            {didPayFee === true ? (
              ""
            ) : (
              <div className="py-2 flex items-center justify-end">
                <div className="">
                  <Checkbox
                    checked={isBook}
                    onChange={handleBookChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <div className="font-mainfont"> : مبلغ الحجز للكورس </div>
              </div>
            )}
            <div className="flex font-mainfont pt-6">
              <Button type="submit" variant="contained" color="primary">
                <div className="font-mainfont">اضافة</div>
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};
