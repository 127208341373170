import { Box, Button, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { DeleteStudentPaymentReq } from "../api/deteleStudentPayment";
import { useMutation, useQueryClient } from "react-query";
const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const DeleteStudentPayment = ({ payment, studentGroupId, row }: any) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  const { mutateAsync } = useMutation(DeleteStudentPaymentReq, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "get-current-student-table",
        studentGroupId,
      ]);
      queryClient.invalidateQueries(["get-current-student-table", row?.courseID, row?.studentID]);
    }
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    try {
      await mutateAsync({
        id: payment.id,
      });

      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  // console.log("payment", payment);

  return (
    <>
      {" "}
      <div className="p-1">
        <Button
          onClick={() => handleOpen()}
          size="small"
          variant="outlined"
          color="error"
        >
          <div className="font-mainfont">حذف</div>
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={styleClasses}>
            <div className="flex justify-between items-center font-mainfont pb-6">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="font-mainfont">ازالة الطالب</div>
            </div>
            <div className="flex justify-end pb-6">
              <div>هل انت متاكد من ازالة الطالب من هذا الصف </div>
            </div>
            {/* <div className="flex justify-end pb-6">
                <div>{adminData?.name} : الاسم</div>
              </div>
              <div className="flex justify-end pb-6">
                <div>{adminData?.email} : الايميل</div>
              </div> */}
            <div className="flex font-mainfont pt-6">
              <Button onClick={onSubmit} variant="contained" color="primary">
                <div className="font-mainfont">نعم</div>
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};
