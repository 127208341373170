import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { decryptionFun } from "../../../lib/secretUrl";
import { SingleSubject } from "../components/singleSubject";
import { CreateGroup } from "../../groups/components/createGroup";
import { GroupList } from "../../groups/components/groupList";
import { GetSubject } from "../../groups/api/getSubject";
import { CreateGroupReq } from "../../groups/api/createGroup";
import { useAuth } from "../../../lib/auth";

export const SingleSubjectWrapper = () => {
  const { user } = useAuth();
  const { encryptedClassId, encryptedSubjectId } = useParams();
  const secretSubjectKey = process.env
    .REACT_APP_SUBJECT_ENCRYPTION_KEY as string;

  const secretClassKey = process.env.REACT_APP_CLASS_ENCRYPTION_KEY as string;

  const subjectId = decryptionFun(encryptedSubjectId, secretSubjectKey);
  const classId = decryptionFun(encryptedClassId, secretClassKey);
  const queryClient = useQueryClient();

  const { data: groupListData, isLoading } = useQuery(
    ["group-list", classId, subjectId, user],
    () => GetSubject(classId, subjectId, user)
  );

  const { mutateAsync: CreateGroupMutate } = useMutation(CreateGroupReq, {
    onSuccess: () => {
      queryClient.invalidateQueries(["subjects", classId, user]);
      queryClient.invalidateQueries(["subject-data", subjectId]);
      queryClient.invalidateQueries(["group-list", classId, subjectId, user]);
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const onCreateGroup = async ({
    data,
    startDate,
    endDate,
    teacher,
    handleClose,
  }: any) => {
    try {
      if (encryptedSubjectId) {
        const subjectId = decryptionFun(encryptedSubjectId, secretSubjectKey);
        const schoolId = user.schoolId;

        const requestData = {
          name: data.groupName,
          teacherId: Number(teacher),
          subjectId: Number(subjectId),
          schoolId: Number(schoolId),
          studentsIds: [],
          teacher_percentage: Number(data.teacher_percentage),
          discount_percentage: 0,
          fee: Number(data.fee.replace(/,/g, "")),
          date_start: startDate,
          date_end: endDate,
        };

        await CreateGroupMutate(requestData);
        handleClose();
      } else {
        console.error("Error: encryptedGrouptId is missing.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="pt-5">
        <SingleSubject />
        <div className="pt-3 p-5">
          <CreateGroup onCreateGroup={onCreateGroup} />
          <GroupList
            groupListData={groupListData?.course}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
