import { Button } from "@mui/material";
import { createContext, useMemo, useState } from "react";
import { Box, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { undeleteClassReq } from "../api/undeleteClassReq";
import { ClassArchiveDelete } from "../api/classArchiveDelete";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
const inputSchema = z.object({
  classId: z.any(),
});

const Context = createContext({ name: "Default" });

const styleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-4 md:w-6/12 sm:w-11/12 xsm:w-11/12";

export const UndeletedClass = ({ row }: any) => {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.error({
      message: `عذرا الرقم الذي ادخلته غير صحيح`,
      // description: <Context.Consumer>{({ name }) => ``}</Context.Consumer>,
      placement,
    });
  };

  const queryClient = useQueryClient();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleEditOpen = () => {
    setDeleteOpen(true);
    setValue("classId", "");
    clearErrors();
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const { mutateAsync: undeleteClassMutate } = useMutation(undeleteClassReq, {
    onSuccess: () => {
      queryClient.invalidateQueries(["deleted-stages"]);
      handleClose();
    },
  });

  const onSubmit = async () => {
    await undeleteClassMutate({ id: row.id });
  };

  function convertArabicToEnglishNumerals(arabicNumerals: string) {
    const arabicToEnglishNumeralsMap: { [key: string]: string } = {
      "۰": "0",
      "۱": "1",
      "۲": "2",
      "۳": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "۷": "7",
      "۸": "8",
      "۹": "9",
    };

    let convertedNumerals = "";

    for (let i = 0; i < arabicNumerals.length; i++) {
      const char = arabicNumerals[i];

      const convertedChar = arabicToEnglishNumeralsMap[char] || char;

      convertedNumerals += convertedChar;
    }

    return convertedNumerals;
  }

  const { mutateAsync: ClassArchiveDeleteMutate } = useMutation(
    ClassArchiveDelete,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["deleted-stages"]);
        handleDeleteClose();
      },
    }
  );

  const onDelete = async (data: any) => {
    try {
      const validatedData = inputSchema.parse(data);
      const enteredId = convertArabicToEnglishNumerals(validatedData.classId);
      const response = await ClassArchiveDeleteMutate({
        id: row.id,
        enteredId,
      });

      if (response.status === 400) {
        openNotification("topLeft");
        handleDeleteClose();
      }
    } catch (error) {
      console.error(error);
      handleDeleteClose();
      openNotification("topLeft");
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);
  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        <div className="md:flex items-center sm:block">
          <div className="p-1">
            <Button
              onClick={() => handleEditOpen()}
              variant="outlined"
              color="error"
            >
              <div className="font-mainfont">حذف</div>
            </Button>
            <Modal
              open={deleteOpen}
              onClose={handleDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styleClasses}>
                <div className="flex justify-between items-center font-mainfont pb-6">
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleDeleteClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div className="font-mainfont">حذف الصف</div>
                </div>
                <div className="items-center font-mainfont pb-6">
                  <div className="flex justify-end">
                    <div className="font-mainfont ">{row.stage_name}</div>
                    <div className="font-mainfont">&nbsp; : اسم الصف</div>
                  </div>
                  <div className="flex justify-end">
                    <div className="font-mainfont font-bold">{row.id}</div>
                    <div className="font-mainfont">&nbsp; : كود الصف</div>
                  </div>
                </div>
                <div className="flex flex-col justify-end pb-6">
                  <div className="flex justify-end pb-6">
                    الرجاء ادخال الكود الخاص بالصف للحذف
                  </div>
                  <form onSubmit={handleSubmit(onDelete)}>
                    <TextField
                      fullWidth
                      {...register("classId", {
                        required: "كود الصف",
                        pattern: {
                          value: /^[\u0660-\u0669\u06F0-\u06F90-9]*$/, // Arabic and English numerals
                          message: "الرجاء ادخال رقم",
                        },
                      })}
                      error={!!errors.classId}
                      helperText={
                        errors.classId ? (
                          <div className="flex justify-end">
                            الرجاء ادخال الكود الخاص بالصف للحذف
                          </div>
                        ) : (
                          ""
                        )
                      }
                      InputLabelProps={{
                        style: {
                          textAlign: "right",
                        },
                      }}
                      inputProps={{
                        style: {
                          textAlign: "right",
                          direction: "rtl",
                        },
                        inputMode: "numeric",
                      }}
                    />
                    <div className="flex font-mainfont pt-6">
                      <Button
                        type="submit"
                        // onClick={() => onDelete()}
                        variant="outlined"
                        color="error"
                      >
                        <div className="font-mainfont">نعم</div>
                      </Button>
                    </div>
                  </form>
                </div>
              </Box>
            </Modal>
          </div>
          <div className="p-1">
            <Button onClick={handleOpen} variant="contained" color="primary">
              <div className="font-mainfont">الغاء الارشفة</div>
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styleClasses}>
                <div className="flex justify-between items-center font-mainfont pb-2">
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div className="font-mainfont">
                    هل انت متاكد من الغاء الارشفة
                  </div>
                </div>
                <div className="flex font-mainfont py-4">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => onSubmit()}
                  >
                    <div className="font-mainfont">تاكيد</div>
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>
        </div>{" "}
      </Context.Provider>
    </>
  );
};
