import CryptoJS from "crypto-js";

export const encryptionFun = (id: number, secretKey: string) => {
  const idAsString = id.toString();
  const encryptedId: string = CryptoJS.AES.encrypt(idAsString, secretKey)
    .toString()
    .replace(/\+/g, "p1L2u3S")
    .replace(/\//g, "s1L2a3S4h")
    .replace(/=/g, "e1Q2u3A4l");
  return encryptedId;
};

export const decryptionFun = (
  encryptedId: string,
  secretKey: string
): number | null => {
  try {
    const decryptedId = encryptedId
      .replace(/p1L2u3S/g, "+")
      .replace(/s1L2a3S4h/g, "/")
      .replace(/e1Q2u3A4l/g, "=");

    const decryptedBytes = CryptoJS.AES.decrypt(decryptedId, secretKey);

    const originalId = parseInt(decryptedBytes.toString(CryptoJS.enc.Utf8), 10);

    return originalId;
  } catch (error) {
    console.error("Error decrypting ID:", error);
    return null;
  }
};
