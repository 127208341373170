import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Breadcrumbs, Link } from "@mui/material";
import { Waveform } from "@uiball/loaders";
import { GetSingleTeacherDataReq } from "../api/getSingleTeacherData";
import { decryptionFun } from "../../../lib/secretUrl";
import { MetaDataCard } from "../../../components/elements/metaDataCard";
import { EditTeacher } from "./editTeacher";
import { DeleteTeacher } from "./deleteTeacher";
import { ROLES, useAuthorization } from "../../../lib/authorization";
import ChangeTeacherPassword from "./changeTeacherPassword";

export const TeacherProfile = () => {
  const { checkAccess } = useAuthorization();
  const { encryptedTeacherId } = useParams();
  const secretKey = process.env.REACT_APP_TEACHER_ENCRYPTION_KEY as string;
  const id = decryptionFun(encryptedTeacherId, secretKey);

  const { data: teacherProfileData, isLoading } = useQuery(
    ["single-teacher-data", id],
    () => GetSingleTeacherDataReq(id)
  );

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center p-16">
            <Waveform size={100} speed={2} color="black" />
          </div>
        ) : (
          <>
            <div className="pl-5 flex justify-between">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/teachers">
                  <div className="font-mainfont text-md">المدرسين</div>
                </Link>
                <div className="font-mainfont text-md">
                  {teacherProfileData?.user.fullName}
                </div>
              </Breadcrumbs>
            </div>
            <div className="flex flex-col px-5 py-10">
              <div className="flex justify-between items-center">
                <div className="flex justify-start gap-4">
                  {checkAccess({
                    allowedRoles: [ROLES.SUPERADMIN],
                  }) &&
                    teacherProfileData?.course?.length === 0 && (
                      <DeleteTeacher
                        TeacherProfileData={teacherProfileData}
                        id={id}
                      />
                    )}
                  <EditTeacher
                    TeacherProfileData={teacherProfileData}
                    id={id}
                  />
                  <ChangeTeacherPassword
                    TeacherProfileData={teacherProfileData}
                  />
                </div>
                <div className="font-mainfont text-3xl">
                  {teacherProfileData?.user.fullName}
                </div>
              </div>
              <div className="border-b-2 border-mainLine pt-4"></div>
            </div>
            <div className="flex flex-wrap justify-end p-3">
              <MetaDataCard
                index={1}
                title="رقم الهاتف"
                data={teacherProfileData?.phone}
              />{" "}
              <MetaDataCard
                index={4}
                title="الاختصاص"
                data={teacherProfileData?.specialty}
              />
              <MetaDataCard
                index={2}
                title="عنوان السكن"
                data={teacherProfileData?.address}
              />{" "}
              <MetaDataCard
                index={3}
                title="اسم المستخدم"
                data={teacherProfileData?.user.userName}
              />{" "}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
