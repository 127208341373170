import React from "react";
import { Route, Routes } from "react-router-dom";
import { ArchiveWrapper } from "./archiveWrapper";
// import { AdminWrapper } from "./adminWrapper";
// import { AdminProfileWrapper } from "./adminProfileWrapper";

export const ArchiveRouter = () => {
  return (
    <Routes>
      <Route index element={<ArchiveWrapper />} />
      {/* <Route path="/:encryptedAdminId" element={<AdminProfileWrapper />} /> */}
    </Routes>
  );
};
