import { axios } from "../../../lib/axios";
// import { Student } from "../types";

export const EditGroupTeacher = async ({ GroupId, body }: any) => {
  //   console.log(id, data);

  const response = await axios.patch(`/courses/${GroupId}`, body);

  return response;
};
