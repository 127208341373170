import { axios } from "../../../lib/axios";
import { User } from "../types";

export const GetSingleAdmin = async (id: number | null): Promise<User> => {
  const response = await axios.get<User>(`/user/${id}`);
  // console.log(response.data)
  return response.data;
};

// done types
